import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {NgxChartComponent, ValueData} from '../ngxChart';
import {TranslateService} from '@ngx-translate/core';
import {Measure} from '../../../measure/_model/measure';
import {v1 as uuidv1} from 'uuid';
import * as svg from 'save-svg-as-png';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {SensorMeasureSetting} from '../../../measure/_model/sensorMeasureSetting';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ngx-line-chart',
  templateUrl: './ngx-line-chart.component.html',
  styleUrls: ['./ngx-line-chart.component.scss']
})
export class NgxLineChartComponent extends NgxChartComponent implements OnInit {
  public readonly uuid: string;
  public minDate: Date = new Date();
  public maxDate: Date = new Date();

  constructor(translate: TranslateService) {
    super(translate);
    this.uuid = uuidv1();
  }

  @Input() newDataReceived: EventEmitter<any> = new EventEmitter();
  @Input() chartName: string;

  public buildSerieName(sensorMeasureSetting: SensorMeasureSetting) {
    let name = '';
    this.translate.get(sensorMeasureSetting.sensorMeasureType.measureType).subscribe((translateName: string) => {
      name = translateName;
    }, () => {
      name = sensorMeasureSetting.sensorMeasureType.measureType;
    });
    name += ' ' + sensorMeasureSetting.storageRule.name;
    return name;
  }

  buildGraph = (): void => {
    this.result = [];
    for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {
      let seriesDatas: ValueData[] = [];
      const dataDatas: any = {};
      dataDatas.name = this.buildSerieName(sensorMeasureSetting);

      if (sensorMeasureSetting.sensorMeasures) {
        for (let dataInfo of sensorMeasureSetting.sensorMeasures) {
          dataInfo = new Measure(dataInfo.id, dataInfo.date, dataInfo.value);
          seriesDatas.push(dataInfo.valueData);
        }
      }


      seriesDatas = this.sortbyDate(seriesDatas);
      if (seriesDatas.length > 0) {

        this.minDate = seriesDatas[0].name;
        this.maxDate = seriesDatas[seriesDatas.length - 1].name;

      }
      dataDatas.series = seriesDatas;
      this.result.push(dataDatas);
    }

    if (this.result.length > 1) {
      let name = '';
      this.translate.get('chart_displayAll').subscribe((translateName: string) => {
        name = translateName;
      });
      const displayAll: any = {name, series: []};
      this.result.push(displayAll);
    }
  };

  ngOnInit(): void {
    this.newDataReceived.subscribe(() => {
      this.buildGraph();
    });
  }

  async exportData() {
    const node = document.getElementById(this.uuid);
    const chartElement = node.getElementsByTagName('svg');

    svg.svgAsPngUri(chartElement[0]).then(uri => {
      const docDefinition = {
        content: [],
        pageOrientation: 'landscape',
        styles: {
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 5, 0, 5],
            alignment: 'center'
          },
          subsubheader: {
            fontSize: 12,
            italics: true,
            margin: [0, 5, 0, 10],
            alignment: 'left'
          },
          legendTitle: {
            fontSize: 12,
            decoration: 'underline',
            margin: [0, 5, 0, 10],
            alignment: 'left'
          },

        }
      };

      const title = {text: this.chartName, style: 'subheader'};
      docDefinition.content.push(title);
      docDefinition.content.push({
        text: 'Date de début: ' + this.minDate.toLocaleDateString('fr-FR') + ' ' +
          this.minDate.toLocaleTimeString('fr-FR') +' Date de fin: ' + this.maxDate.toLocaleDateString('fr-FR') + ' ' +
          this.maxDate.toLocaleTimeString('fr-FR'), style: 'subsubheader'
      });

      docDefinition.content.push({image: uri, width: 700});
      docDefinition.content.push({text: this.legendTitle, style: 'legendTitle'});
      let i = 0;
      for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {
        const name = 'Capteur: ' + sensorMeasureSetting.sensorMeasureType.displayName + ' Valeur: ' + sensorMeasureSetting.storageRule.name;
        docDefinition.content.push({
          text: name, style: {
            fontSize: 12,
            margin: [20, 10, 0, 25],
            alignment: 'left',
            color: this.colorScheme.domain[i]
          }
        });
        i++;
      }
      pdfMake.createPdf(docDefinition).download(this.chartName +'_'+ this.minDate.toLocaleDateString('fr-FR')+ '.pdf');
    });
  }
}
