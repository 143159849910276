import {Sensor} from './Sensor';
import {MeasureSession} from '../../measure/_model/measureSession';

export class System {
  id: number;
  softVersion: string;
  sn: string;
  pn: string;
  model: string;
  hardVersion: string;
  MAC_ADDR: string;
  maintenance: number;

  ipAddress: string;
  name: string;
  sensors: Sensor[];
  lastSync: Date;
  measureSessions: MeasureSession[];
}
