export const frPopUpTranslate = {
  popUp_mail: 'Email',
  popUp_validate: 'Valider',
  popUp_cancel: 'Annuler',
  popUp_yes: 'Oui',
  popUp_no: 'Non',
  popUp_exportTable: 'Exporter les données',
  popUp_format: 'Choississez le format de fichier',
  popUp_currentRole: 'Role actuel : ',
  popUp_changeRole: 'Changez le role'
};

export const enPopUpTranslate = {
  popUp_mail: 'E-mail',
  popUp_validate: 'Validate',
  popUp_cancel: 'Cancel',
  popUp_yes: 'Yes',
  popUp_no: 'No',
  popUp_exportTable: 'Export data',
  popUp_format: 'Choose the file format',
  popUp_currentRole: 'Current role : ',
  popUp_changeRole: 'Change the role',
};

export const dePopUpTranslate = {
  popUp_mail: 'E-Mail',
  popUp_validate: 'Validate',
  popUp_cancel: 'Stornieren',
  popUp_yes: 'Ja',
  popUp_no: 'Nicht',
  popUp_exportTable: 'Daten exportieren',
  popUp_format: 'Wählen Sie das Dateiformat',
};

export const esPopUpTranslate = {
  popUp_mail: 'E-mail',
  popUp_validate: 'Validar',
  popUp_cancel: 'Anular',
  popUp_yes: 'Sí',
  popUp_no: 'No',
  popUp_exportTable: 'Exportar datos',
  popUp_format: 'Elige el formato de archivo',
};
