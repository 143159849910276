import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { HttpErrorResponse} from '@angular/common/http';

import {first} from 'rxjs/operators';
import {StorageRuleService} from '../_service/storageRule.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-storage-rule-create',
  templateUrl: './storage-rule-create.component.html',
  styleUrls: ['./storage-rule-create.component.css']
})
export class StorageRuleCreateComponent implements OnInit {
  form: FormGroup;
  constructor(public formBuilder: FormBuilder, public dialogRef: MatDialogRef<StorageRuleCreateComponent>,
              public storageRuleService: StorageRuleService ) { }
  statisticProcessType: any[] = [
    {value: 'average', viewValue: 'Moyenne'},
    {value: 'standardDeviation', viewValue: 'Ecart type'},
    {value: 'min', viewValue: 'Valeur min'},
    {value: 'max', viewValue: 'Valeur Max'},
    {value: 'raw', viewValue: 'Donnée brute'}
  ];
  ngOnInit() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      acquisitionPeriod: new FormControl('', [Validators.min(1), Validators.max(1000)]),
      computeSample: new FormControl('', [Validators.min(1), Validators.max(1000)]),
      sampleCount: new FormControl('', [Validators.min(1), Validators.max(1000)]),
      enableByDefault: new FormControl(false, [Validators.required]),
      statisticProcessType: new FormControl(true, [Validators.required]),
      centeredMeasure: new FormControl(false, [Validators.required]),
    });
  }

  onDismiss() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    if (this.form.invalid) {
      console.log('form invalid');
      console.log(this.form);
      return;
    } else {

      this.storageRuleService.create(this.form.value).pipe(
        first()
      ).subscribe(
        (): void => {
          console.log('storageRule created successful', true);
          this.dialogRef.close(true);
        },
        (err: HttpErrorResponse): void => {
          console.log(err);
          const errMsg: string = err.error.error;
          console.log(errMsg) ;
        }
      );

    }
  }
}
