import {Component, Inject, OnInit} from '@angular/core';

import {first} from 'rxjs/operators';
import {RoleService} from '../../../userRole/_service/role.service';
import {frPopUpTranslate} from '../_translation/translate';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-pop-up-change-role',
  templateUrl: './pop-up-change-role.component.html',
  styleUrls: ['./pop-up-change-role.component.scss']
})
export class PopUpChangeRoleComponent implements OnInit {

  firstName: string;
  lastName: string;
  roleName: string;
  listRole: any;

  constructor(
    public dialogRef: MatDialogRef<PopUpChangeRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public roleService: RoleService) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.roleName = data.roleName;
  }

  ngOnInit(): void {
    this.roleService.getRoleList()
      .pipe(first()).subscribe(
      (data: any) => {
        this.listRole = data;
      },
      (): void => {
        this.listRole = null;
      });
  }

  buttonClick = (): void => {
    this.dialogRef.close();
  }
}
