import {Component, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../_service/user.service';
import {first} from 'rxjs/operators';
import {User} from '../_model/user';
import {Info} from '../../shared/display-info/display-info.component';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  dataInfo: Info[];

  user: User;

  constructor(public router: Router,
              public userService: UserService,
              public activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.userService.getUser(this.activatedRoute.snapshot.paramMap.get('id'))
      .pipe(first()).subscribe(
      (data: any): void => {
        this.user = data;
        this.dataInfo = [
          {name: 'user_id', value: data.id, icon: 'perm_identity'},
          {name: 'user_mail', value: data.email, icon: 'email'},
          {name: 'user_phone', value: data.phone, icon: 'phone'},
          {name: 'user_role', value: data.role.roleName, icon: 'vpn_key'},
        ];
      },
      (): void => {
        this.user = null;
      }
    );
  }

  btnClick = (dest: string, id?: string): void => {
    if (id) {
      this.router.navigateByUrl('/' + dest + '/' + id);
    } else {
      this.router.navigateByUrl('/' + dest + '/' + this.activatedRoute.snapshot.paramMap.get('id'));
    }
  }

  public navigateToUserList = () => {
    this.router.navigateByUrl('/user/list');
  }
}

