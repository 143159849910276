import {Component, Input, OnInit} from '@angular/core';
import {Info} from '../../shared/display-info/display-info.component';
import {System} from '../../system/_model/System';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MeasureService} from '../../measure/_service/measure.service';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {MeasureSession} from '../../measure/_model/measureSession';

@Component({
  selector: 'app-system-widget',
  templateUrl: './system-widget.component.html',
  styleUrls: ['./system-widget.component.css']
})
export class SystemWidgetComponent implements OnInit {
  public dataInfo: Info[];
  public dataLoaded: Promise<boolean>;
  @Input() system: System;
  public measureSession: MeasureSession;

  constructor(public router: Router,
              public measurService: MeasureService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.dataInfo = [];
    this.dataLoaded = Promise.resolve(false);
    this.measurService.getLastMeasure(this.system.id).pipe(first()).subscribe(
      (data) => {
        this.measureSession = data.measureSession;

        for (const setting of this.measureSession.sensorMeasureSettingEntities) {
          if (setting.sensorMeasureType.measureType === 'HEIGHT' && setting.sensorMeasures.length > 0) {
            this.dataInfo.push({
              name: 'HEIGHT',
              value: String(setting.sensorMeasures[0].value+' '+setting.sensorMeasureType.unit+' - '+setting.storageRule.name),
              hint: setting.sensorMeasures[0].date,
              icon: 'swap_calls'
            },);
          }
          if (setting.sensorMeasureType.measureType === 'SPEED' && setting.sensorMeasures.length > 0) {
            this.dataInfo.push({
              name: 'SPEED',
              value: String(setting.sensorMeasures[0].value+' '+setting.sensorMeasureType.unit+' - '+setting.storageRule.name),
              hint: setting.sensorMeasures[0].date,
              icon: 'toys'
            },);
          }
          if (setting.sensorMeasureType.measureType === 'DIR' && setting.sensorMeasures.length > 0) {
            this.dataInfo.push({
              name: 'DIR',
              value: String(setting.sensorMeasures[0].value+' '+setting.sensorMeasureType.unit+' - '+setting.storageRule.name),
              hint: setting.sensorMeasures[0].date,
              icon: 'near_me'
            },);
          }
          if (setting.sensorMeasureType.measureType === 'TEMP' && setting.sensorMeasures.length > 0) {
            this.dataInfo.push({
              name: 'TEMP',
              value: String(setting.sensorMeasures[0].value+' '+setting.sensorMeasureType.unit+' - '+setting.storageRule.name),
              hint: setting.sensorMeasures[0].date,
              icon: 'wb_sunny'
            },);
          }
          if (setting.sensorMeasureType.measureType === 'batLevl' && setting.sensorMeasures.length > 0) {
            this.dataInfo.push({
              name: 'batLevel',
              value: String(setting.sensorMeasures[0].value+' '+setting.sensorMeasureType.unit+' - '+setting.storageRule.name),
              hint: setting.sensorMeasures[0].date,
              icon: 'battery_charging_full'
            },);
          }
        }
        this.dataLoaded = Promise.resolve(true);
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }

  btnClick = (dest: string) => {
    this.router.navigateByUrl('/' + dest);
  };
}
