import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoginResponse, User} from '../_model/user';

const DEFAULT_SKIP = 0;
const DEFAULT_TAKE = 1000;

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public httpOptions = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });

  constructor(public http: HttpClient) {
  }

  public register(user: User) {
    const body = {
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      email: user.email,
      confirmPassword: user.password,
      phone: user.phone
    };
    return this.http.post<any>(environment.apiUrl + '/user/create', JSON.stringify(body), {headers: this.httpOptions});
  }

  public getUser(id: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/user/get/' + id, {headers: this.httpOptions});
  }

  public getUserList(skip?: number, take?: number): Observable<any> {
    skip = (skip) ? skip : DEFAULT_SKIP;
    take = (take) ? take : DEFAULT_TAKE;
    return this.http.get<any>(environment.apiUrl + '/user/list' + '?skip=' + skip + '&take=' + take, {headers: this.httpOptions});
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/user/delete/' + id, {headers: this.httpOptions});
  }

  public updateUser(id: string, body: any): Observable<any> {
    console.log(body);
    return this.http.post<any>(environment.apiUrl + '/user/update/' + id, body, {headers: this.httpOptions});
  }

  login(username: string, password: string) {

    console.log( ' API '+environment.apiUrl);

    return this.http.post<any>(environment.apiUrl + '/user/login',
      {
        email: username,
        password,
      }
    )
      .pipe(map((response: LoginResponse) => {
        // login successful if there's a jwt token in the response
        if (response && response.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          response.user.password = '';
          localStorage.setItem('currentUser', JSON.stringify(response));
          return response.user;
        }
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  forgetPassWord(email: string) {
    return this.http.get<any>(environment.apiUrl + '/user/forgetPassword/' + email, {headers: this.httpOptions});
  }

  launchCrash() {
    return this.http.get<any>(environment.apiUrl + '/dev/crash', {headers: this.httpOptions});
  }

}
