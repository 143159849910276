import { Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Measure} from '../../measure/_model/measure';
import {SensorMeasureSetting} from '../../measure/_model/sensorMeasureSetting';

export interface Name {
  name: string;
  param: string | number;
  values?: Measure[];
}

export interface ValueData {
  name: Date;
  value: any;
}

export abstract class NgxChartComponent {

  @Input() sensorMeasureSettingEntities: SensorMeasureSetting[];
  @Input() data: any[];
  @Input() listName: Name[] = [];

  result: any = [];
  showXAxisLabel = false;
  showYAxisLabel = false;
  xAxisTicks: Date[] = [];

  legendTitle = '';

  @Input() yAxis = true;
  @Input() xAxis = true;

  @Input() yAxisLabel = '';
  @Input() xAxisLabel = '';

  @Input() yScaleMax;
  @Input() yScaleMin;

  @Input() legend = true;

  @Input() animations = true;
  @Input() timeline = false;
  @Input() gradient = false;

  @Input() view: number[] = [];

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#5DADE2', '#C7B42C']
  };
  tmpdata: any[] = [];

  constructor(protected translate: TranslateService) {
    this.view = (!this.view) ? [window.innerWidth / 1.85, window.innerHeight / 2.75] : this.view;
  }

  abstract buildGraph(data: any[]): void;

  ngOnChanges(): void {
    if (this.yAxisLabel) {
      this.showYAxisLabel = true;
    }
    if (this.xAxisLabel) {
      this.showXAxisLabel = true;
    }
    if (this.xAxisTicks) {
      this.showXAxisLabel = true;
    }
    let legendTitle = '';
    this.translate.get('chart_legend').subscribe((title: string) => {
      legendTitle = title;
    });

    this.legendTitle = legendTitle;
    this.buildGraph(this.data);
    this.onResize(undefined);
  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      const date = val as Date;
      return date.toLocaleDateString('fr-FR') + ' ' + date.toLocaleTimeString('fr-FR');
    }
  }

  onResize = (event: any): void => {
    this.view = (event) ?
      [event.target.innerWidth / 1.1, event.target.innerHeight / 2] :
      [window.innerWidth / 1.1, window.innerHeight / 2];
  };
  setTimeline(): void {
    this.timeline = !this.timeline;
  }
  onLegendLabelClick = (legendTitle: string): void => {
    if (this.listName.length > 1) {
      if (legendTitle === 'Tout afficher') {
        for (const data of this.result) {
          for (const ref of this.tmpdata) {
            if (data.name === ref.name) {
              data.series = ref.series;
              break;
            }
          }
        }
        this.tmpdata = [];
      } else if (typeof legendTitle === typeof '') {
        for (const data of this.result) {
          for (const ref of this.tmpdata) {
            if (data.name === ref.name) {
              data.series = ref.series;
              break;
            }
          }
        }

        this.tmpdata = [];

        for (const index in this.result) {
          if (this.result[index].name !== legendTitle && this.result.hasOwnProperty(index)) {
            this.tmpdata.push(Object.assign({}, this.result[index]));
            this.result[index].series = [];
          }
        }
      }
      this.result = [...this.result];
    }
  };

  sortbyDate = (list: ValueData[]): ValueData[] => {
    list.sort((a: ValueData, b: ValueData): number => {
      if (a.name <= b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    return list;
  };
}
