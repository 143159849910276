import {Component, EventEmitter, Input, OnInit,} from '@angular/core';
import {SensorMeasureSetting} from '../../_model/sensorMeasureSetting';
import {ExportTablePopUpComponent} from '../../../shared/Pop-up/export-table-pop-up/export-table-pop-up.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-measure-sensor-drawer',
  templateUrl: './measure-sensor-drawer.component.html',
  styleUrls: ['./measure-sensor-drawer.component.css'],
})
export class MeasureSensorDrawerComponent implements OnInit {
  @Input() name: string = null;
  @Input() sensorType: string = null;
  @Input() sensorMeasureSettingEntities: SensorMeasureSetting[] = [];
  @Input() newDataReceivedFromParent: EventEmitter<any> = new EventEmitter();
  public newDataReceived: EventEmitter<any> = new EventEmitter();

  public timeline = false;

  public measureSettingEntitiesMap: Map<string, SensorMeasureSetting[]> = new Map<string, SensorMeasureSetting[]>();

  constructor(public dialog: MatDialog) {

  }


  ngOnInit(): void {
    this.newDataReceivedFromParent.subscribe(() => {
      this.sortSensorMeasureSettingByUnitType();
      this.newDataReceived.emit(null);
    });
  }

  public sortSensorMeasureSettingByUnitType = (): void => {
    this.measureSettingEntitiesMap.clear();

    for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {
      if (!this.measureSettingEntitiesMap.get(sensorMeasureSetting.sensorMeasureType.unitType)) {
        this.measureSettingEntitiesMap.set(sensorMeasureSetting.sensorMeasureType.unitType, []);
      }
      this.measureSettingEntitiesMap.set(sensorMeasureSetting.sensorMeasureType.unitType,
        this.measureSettingEntitiesMap.get(sensorMeasureSetting.sensorMeasureType.unitType).concat([sensorMeasureSetting]));
    }

  };

  public getSensorMeasureSettingByUnitTyp(unitType: string) {
    let result : SensorMeasureSetting[] = [];
    if (this.measureSettingEntitiesMap.get(unitType)) {
      for (const array of this.measureSettingEntitiesMap.get(unitType)) {
        result = result.concat(array);
      }
      return result;
    }
  }



  public exportData = async (type: string): Promise<void> => {
    if (type === 'table') {
      this.dialog.open(ExportTablePopUpComponent, {
        data: {sensorMeasureSettingEntities: this.sensorMeasureSettingEntities}
      });
    }
  };
}
