import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../_service/user.service';
import {first} from 'rxjs/operators';

import {InputPopUpComponent} from '../../shared/Pop-up/input-pop-up/input-pop-up.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogModel} from '../../shared/Pop-up/_model/ConfirmDialogModel';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {
  loading: false;
  loginForm: FormGroup;
  submitted = false;
  error: string | HttpErrorResponse = '';

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public dialog: MatDialog,
    public translate: TranslateService) {
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.userService.logout();
  }

  onKeydown = (event: any): void => {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  };

  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  };

  onSubmit = (): void => {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      console.log('form invalid');
      console.log(this.loginForm.errors);
      return;
    }

    this.userService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        (res): void => {
          this.router.navigateByUrl('/home').then(r => {
            if (!r) {
              console.log('unable to access page userView');
            }
          });
        },
        (error: HttpErrorResponse): void => {
          if (error) {
            if (error.error) {
              if (error.error.error) {
                this.error = error.error.error;
              } else {
                this.error = error.error;
              }
            } else {
              this.error = error;
            }
          }
        });
  };

  passwordForget = () => {
    let message = '';
    let title = '';

    this.translate.get('user_textPopUpPassword', {value: 'user_textPopUpPassword'}).subscribe((textMsg: string) => {
      message = textMsg;
    });

    this.translate.get('user_forgetPass', {value: 'user_forgetPass'}).subscribe((textTitle: string) => {
      title = textTitle;
    });

    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(InputPopUpComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: string) => {
      console.log(dialogResult);
      if (dialogResult) {
        this.userService.forgetPassWord(dialogResult).pipe(first()).subscribe(
          (): void => {
            window.location.reload();
          },
          (error: HttpErrorResponse): void => {
            if (error) {
              if (error.error) {
                if (error.error.error) {
                  this.error = error.error.error;
                } else {
                  this.error = error.error;
                }
              } else {
                this.error = error;
              }
            }
          }
        );
      }
    });
  };

}
