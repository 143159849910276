import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UserService} from '../_service/user.service';
import {Router} from '@angular/router';

import {User} from '../_model/user';
import {first} from 'rxjs/operators';
import {RegistrationValidator} from '../validator/register.validator';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})


export class UserCreateComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  constructor(public http: HttpClient, public formBuilder: FormBuilder, public userService: UserService, public router: Router) {
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email]
      ],
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
    }, {
      validator: [
        RegistrationValidator.validate.bind(this),
        RegistrationValidator.strongPassword.bind(this)
      ]
    });
  }

  get f(): {[key: string]: AbstractControl} {
    return this.registerForm.controls;
  }
  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  }

  onSubmit = (): void => {
    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log('form invalid');
      console.log(this.registerForm.errors);
      return;
    }

    this.loading = true;
    const user: User = new User();
    user.lastName = this.f.lastName.value;
    user.firstName  = this.f.firstName.value;
    user.password = this.f.password.value;
    user.email = this.f.email.value;
    user.phone = this.f.phoneNumber.value;
    this.userService.register(user).pipe(
      first()
    ).subscribe(
      (): void => {
        console.log('Registration successful', true);
        this.router.navigate(['/user/login']).then(r => {
          if (!r) {
            console.log('unable to access page login');
          }
        });
      },
      (err: HttpErrorResponse): void => {
        console.log(err);
        const errMsg: string = err.error.error;
        if (errMsg.indexOf('phone') >= 0) {
          this.registerForm.controls.phoneNumber.setErrors({incorrect: true});
        }
        if (errMsg.indexOf('email') >= 0) {
          this.registerForm.controls.email.setErrors({alreadyUsed: true});
        }
      }
    );
    this.loading = false;
  }
}
