import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MeasureSession} from '../_model/measureSession';
import {SensorMeasureSetting} from '../_model/sensorMeasureSetting';
import {System} from '../../system/_model/System';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';


@Component({
  selector: 'app-measure-details-view',
  templateUrl: './measure-details-view.component.html',
  styleUrls: ['./measure-details-view.component.css']
})
export class MeasureDetailsViewComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Input() measureSession: MeasureSession = null;
  @Input() system: System;
  dataSource: MatTableDataSource<SensorMeasureSetting>;

  constructor() {
  }

  displayedColumns: string[] = ['sensorName', 'measureType', 'statisticProcessType', 'acquisitionPeriod', 'computeSample'];

  ngOnInit(): void {
    console.log(this.measureSession);
    this.dataSource = new MatTableDataSource(this.measureSession.sensorMeasureSettingEntities);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }


  onSortData(sort: Sort) {
    const data =this.dataSource.data;
    if (sort.active && sort.direction !== '') {
      return data.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';
        switch (this.sort.active) {
          case 'sensorName':
            return compare(a.sensor.name, b.sensor.name, isAsc);
          case 'measureType':
            return compare(a.sensorMeasureType.measureType, b.sensorMeasureType.measureType, isAsc);
          case 'statisticProcessType':
            return compare(a.storageRule.statisticProcessType, b.storageRule.statisticProcessType, isAsc);
          case 'acquisitionPeriod':
            return compare(a.storageRule.acquisitionPeriod, b.storageRule.acquisitionPeriod, isAsc);
          case 'computeSample':
            return compare(a.storageRule.computeSample, b.storageRule.computeSample, isAsc);
          case 'sampleCount':
            return compare(a.storageRule.sampleCount, b.storageRule.sampleCount, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  public getIconName(state: string) {
    if (state === 'created') {
      return 'schedule';
    } else if (state === 'aborted') {
      return 'cancel_schedule_send';
    } else if (state === 'measuring') {
      return 'move_to_inbox';
    } else if (state === 'finished') {
      return 'done';
    }
    return 'device_unknown';
  }
}
function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
