import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GlobalEventsManager} from '../_helpers';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(public router: Router, public globalEventsManager: GlobalEventsManager) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      this.globalEventsManager.loginEvent.emit(true);
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/user/login']);
    this.globalEventsManager.loginEvent.emit(false);
    return false;
  }
}
