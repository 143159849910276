import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';
import {StorageRule} from '../_model/StorageRule';

@Injectable({
  providedIn: 'root'
})

export class StorageRuleService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(public http: HttpClient) {
  }

  public list() {
    return this.http.get<any>(environment.apiUrl + '/storageRule/list/', this.httpOptions);
  }
  public  create(storageRule: StorageRule) {
    return this.http.post<any>(environment.apiUrl + '/storageRule/create', JSON.stringify(storageRule),  this.httpOptions);
  }
}
