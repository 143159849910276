import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {dePopUpTranslate, enPopUpTranslate, esPopUpTranslate, frPopUpTranslate} from './_translation/translate';
import {InputPopUpComponent} from './input-pop-up/input-pop-up.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {ExportTablePopUpComponent} from './export-table-pop-up/export-table-pop-up.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {PopUpChangeRoleComponent} from './pop-up-change-role/pop-up-change-role.component';

@NgModule({
  declarations: [
    InputPopUpComponent,
    ConfirmDialogComponent,
    ExportTablePopUpComponent,
    PopUpChangeRoleComponent
  ],
  exports: [
    InputPopUpComponent,
    ConfirmDialogComponent,
    ExportTablePopUpComponent,
    PopUpChangeRoleComponent
  ],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    CommonModule,
    TranslateModule.forChild(),
  ],
})
export class PopUpModule {

  constructor(translate: TranslateService) {
    translate.setTranslation('fr', frPopUpTranslate, true);
    translate.setTranslation('en', enPopUpTranslate, true);
    translate.setTranslation('de', dePopUpTranslate, true);
    translate.setTranslation('es', esPopUpTranslate, true);
  }
}
