import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemService} from '../_service/system.service';
import {first} from 'rxjs/operators';

import {HttpErrorResponse} from '@angular/common/http';
import {System} from '../../../_models';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogModel} from '../../shared/Pop-up/_model/ConfirmDialogModel';
import {ConfirmDialogComponent} from '../../shared/Pop-up/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-system-list',
  templateUrl: './system-list.component.html',
  styleUrls: ['./system-list.component.css']
})
export class SystemListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() showHeader = true;
  dataSource: MatTableDataSource<System[]>;
  isAdmin = false;

  constructor(public router: Router, public systemService: SystemService,
              public dialog: MatDialog, public translate: TranslateService) {
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = ['name', 'sn', 'pn', 'actions'];

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.systemService.systemList()
      .pipe(first()).subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data.systemDevices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.user.role.roleName.toLowerCase() === 'admin') {
      this.isAdmin = true;
    }
  }

  removeSystemFromUserList(id: number) {

    let message = '';
    let title = '';

    this.translate.get('remove_systemFromUserList').subscribe((textMsg: string) => {
      message = textMsg ;
    });

    this.translate.get('confirm_systemFromUserList').subscribe((textTitle: string) => {
      title = textTitle;
    });

    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.systemService.systemRemoveUser(id).pipe(first()).subscribe(
          data => {
            this.loadData();
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        );
      }
    });
  }

  removeSystem(id: number) {
    let message = '';
    let title = '';

    this.translate.get('remove_system').subscribe((textMsg: string) => {
      message = textMsg ;
    });

    this.translate.get('confirmDelete').subscribe((textTitle: string) => {
      title = textTitle;
    });

    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.systemService.systemRemove(id).pipe(first()).subscribe(
          data => {
            this.loadData();
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        );
      }
    });
  }

  btnClick = (dest: string) => {
    this.router.navigateByUrl('/' + dest);
  };

}
