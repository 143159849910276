import {Component, enableProdMode} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {deTranslate, enTranslate, esTranslate, frTranslate} from './utils/languageConst';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(translate: TranslateService) {
    // set translation for all languages.
    translate.setTranslation('en', enTranslate, true);
    translate.setTranslation('fr', frTranslate, true);
    translate.setTranslation('es', esTranslate, true);
    translate.setTranslation('de', deTranslate, true);

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang((localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'fr');
  }
}
