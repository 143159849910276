import { Injectable } from '@angular/core';
import {Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {System} from '../../../_models';

import {EMPTY , Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {StorageRuleService} from '../_service/storageRule.service';

@Injectable()
export class StorageRuleResolver implements Resolve<any> {

  constructor(public storageRuleService: StorageRuleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.storageRuleService.list().pipe(
      catchError((error) => {
        return EMPTY;
      })
    );
  }
}
