import {Component, Input, OnInit} from '@angular/core';
import {System} from '../_model/System';

import {ConfirmDialogModel} from '../../shared/Pop-up/_model/ConfirmDialogModel';
import {ConfirmDialogComponent} from '../../shared/Pop-up/confirm-dialog/confirm-dialog.component';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SystemConfigurationComponent} from '../system-configuration/system-configuration.component';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {MeasureService} from '../../measure/_service/measure.service';


@Component({
  selector: 'app-system-information',
  templateUrl: './system-information.component.html',
  styleUrls: ['./system-information.component.css']
})
export class SystemInformationComponent implements OnInit {
  @Input() system: System;
  panelOpenState: boolean;
  public isAdmin: boolean;
  public battery: number;
  public memory: number;
  public isLow = false;
  public dataLoaded: Promise<boolean>;


  constructor(public dialog: MatDialog, public measurService: MeasureService, public eponimWebSocketService: EponimWebSocketService) {
  }

  ngOnInit() {
    const loginResponse = JSON.parse(localStorage.getItem('currentUser'));
    this.isAdmin = (loginResponse.user.role.roleName.toLowerCase() === 'admin');
    this.measurService.getLastMeasure(this.system.id).pipe(first()).subscribe(
      (data) => {
        console.log(data);
        const measureSession = data.measureSession;
        for (const setting of measureSession.sensorMeasureSettingEntities) {
            if (setting.sensorMeasureType.measureType === 'batLevl' && setting.sensorMeasures.length > 0) {
              this.battery = setting.sensorMeasures[0].value;
              if (this.battery < 12){
                this.isLow = true;
              }
            }
            if (setting.sensorMeasureType.measureType === 'freeMem' && setting.sensorMeasures.length > 0) {
              this.memory = Math.round(setting.sensorMeasures[0].value);
            }
          this.dataLoaded = Promise.resolve(true);
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }

  turnOff() {
    let title: string;
    title = 'shutdown_title';
    const message = 'shutdown_message';
    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async (dialogResult: boolean) => {
      if (dialogResult) {
        await this.eponimWebSocketService.turnOff(this.system);
      }
    });
  }

  restart() {

    const title = 'reboot_title';
    const message = 'reboot_message';
    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async (dialogResult: boolean) => {
      if (dialogResult) {
        await this.eponimWebSocketService.restart(this.system);
      }
    });
  }

  public showSystemSetting() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {system: this.system};
    dialogConfig.maxWidth = 720;
    dialogConfig.maxHeight= 800;
    this.dialog.open(SystemConfigurationComponent, dialogConfig);
  }

  public getPanelStyle() {
    if (this.panelOpenState) {
      return 'margin-top: 20px; color: white';
    } else {
      return 'margin-top: 20px;';
    }

  }
}
