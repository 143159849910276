import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {GlobalEventsManager, JwtInterceptor, LogInterceptor} from './_helpers';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {A11yModule} from '@angular/cdk/a11y';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PortalModule} from '@angular/cdk/portal';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ConfirmDialogComponent} from './component/shared/Pop-up/confirm-dialog/confirm-dialog.component';
import {SystemListComponent} from './component/system/system-list/system-list.component';
import {SystemViewComponent} from './component/system/system-view/system-view.component';
import {SystemPairingComponent} from './component/system/system-pairing/system-pairing.component';
import {SystemInformationComponent} from './component/system/system-information/system-information.component';
import {MeasureListComponent} from './component/measure/measure-list/measure-list.component';
import {MeasureViewComponent} from './component/measure/measure-view/measure-view.component';
import {MeasureCreateComponent} from './component/measure/measure-create/measure-create.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MeasureSensorViewComponent} from './component/measure/sensorDataViewer/measure-sensor-view/measure-sensor-view.component';
import {MeasureActionViewComponent} from './component/measure/measure-action-view/measure-action-view.component';
import {ChartingModule} from './component/shared/chart/chartingModule';
import {SystemMonitoringViewComponent} from './component/system/system-monitoring-view/system-monitoring-view.component';
import {SystemDebugViewComponent} from './component/system/system-debug-view/system-debug-view.component';
import {MeasureSensorDrawerComponent} from './component/measure/sensorDataViewer/measure-sensor-drawer/measure-sensor-drawer.component';
import {MeasureStateViewComponent} from './component/measure/sensorDataViewer/measure-state-view/measure-state-view.component';
import {SensorListComponent} from './component/system/sensor-list/sensor-list.component';
import {SystemConfigurationComponent} from './component/system/system-configuration/system-configuration.component';
import {StorageRuleCreateComponent} from './component/storageRule/storage-rule-create/storage-rule-create.component';
import {StorageRuleListComponent} from './component/storageRule/storage-rule-list/storage-rule-list.component';
import {SystemDetailResolver} from './component/system/_resolver/system-detail-resolver';
import {StorageRuleResolver} from './component/storageRule/_resolver/storage-rule-resolver';
import {InputPopUpComponent} from './component/shared/Pop-up/input-pop-up/input-pop-up.component';
import {SensorSettingViewComponent} from './component/sensorSetting/sensor-setting-view/sensor-setting-view.component';
import {SensorSettingCreateComponent} from './component/sensorSetting/sensor-setting-create/sensor-setting-create.component';
import {OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {MomentModule} from 'ngx-moment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UserModule} from './component/user/userModule';
import {UserRoleModule} from './component/userRole/userRoleModule';
import {PopUpModule} from './component/shared/Pop-up/popUpModule';
import {NavigationModule} from './component/navigation/navigationModule';
import {MapModule} from './component/shared/map/mapModule';
import {MaterialModule} from './materialModule';
import {DisplayStorageRulesComponent} from './component/storageRule/display-storage-rules/display-storage-rules.component';
import {MeasureDataViewerComponent} from './component/measure/measure-data-viewer/measure-data-viewer.component';
import {MeasureSensorDataTableViewerComponent} from './component/measure/sensorDataViewer/measure-sensor-data-table-viewer/measure-sensor-data-table-viewer.component';
import {ExportTablePopUpComponent} from './component/shared/Pop-up/export-table-pop-up/export-table-pop-up.component';
import {OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import {MatInputModule} from '@angular/material/input';
import { MeasureDetailsViewComponent } from './component/measure/measure-details-view/measure-details-view.component';
import { DashboardViewComponent } from './component/dashboard/dashboard-view/dashboard-view.component';
import { SystemWidgetComponent } from './component/dashboard/system-widget/system-widget.component';
import {DisplayInfoModule} from './component/shared/display-info/displayInfoModule';
import {DatePipe} from '@angular/common';
import { LoaderComponent } from './component/shared/loader/loader.component';
import { LoaderInterceptor } from './component/shared/loader/_interceptor/loader.interceptor';
import { FooterComponent } from './component/shared/footer/footer.component';
import { LegalNoticeComponent } from './component/legal-notice/legal-notice.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SystemListComponent,
    SystemViewComponent,
    SystemPairingComponent,
    SystemInformationComponent,
    MeasureListComponent,
    MeasureViewComponent,
    MeasureCreateComponent,
    MeasureSensorViewComponent,
    MeasureActionViewComponent,
    SystemMonitoringViewComponent,
    SystemDebugViewComponent,
    MeasureSensorDrawerComponent,
    MeasureSensorViewComponent,
    MeasureStateViewComponent,
    SensorListComponent,
    SystemConfigurationComponent,
    StorageRuleCreateComponent,
    StorageRuleListComponent,
    SensorSettingViewComponent,
    SensorSettingCreateComponent,
    DisplayStorageRulesComponent,
    MeasureDataViewerComponent,
    MeasureSensorDataTableViewerComponent,
    MeasureDetailsViewComponent,
    DashboardViewComponent,
    SystemWidgetComponent,
    LoaderComponent,
    FooterComponent,
    LegalNoticeComponent
  ],
  imports: [
    MomentModule.forRoot(),
    MapModule,
    MatInputModule,
    PopUpModule,
    NavigationModule,
    UserModule,
    UserRoleModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MaterialModule,
    PortalModule,
    ScrollingModule,
    NgxChartsModule,
    ChartingModule,
    FlexLayoutModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    DisplayInfoModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    {provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    DatePipe,
    GlobalEventsManager,
    SystemDetailResolver,
    StorageRuleResolver
  ],
  entryComponents: [ConfirmDialogComponent,
    InputPopUpComponent,
    StorageRuleCreateComponent,
    SensorSettingCreateComponent,
    SystemConfigurationComponent,
    ExportTablePopUpComponent],
  bootstrap: [AppComponent],
  exports: [MaterialModule],
})
export class AppModule {
}
