import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RegistrationValidator} from '../../user/validator/register.validator';
import {Sensor} from '../_model/Sensor';
import {ActivatedRoute, Router} from '@angular/router';
import {System} from '../_model/System';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {first} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {SystemService} from "../_service/system.service";


export interface DialogData {
  system: System;
}

@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.css']
})
export class SystemConfigurationComponent implements OnInit {
  system: System;
  sensors: Sensor[];
  editForm: FormGroup;
  submitted = false;
  regexIpV4: RegExp;

  constructor(public formBuilder: FormBuilder,
              public systemService: SystemService,
              public activatedRoute: ActivatedRoute,
              public router: Router,
              public eponimWebSocketService: EponimWebSocketService,
              public dialogRef: MatDialogRef<SystemConfigurationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.system= data.system;
    this.editForm = this.formBuilder.group({
         maintenance: new FormControl('', [Validators.required, Validators.min(0), Validators.max(1)])
    }, {
      validator: [
        RegistrationValidator.validate.bind(this),
      ]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.editForm.controls;
  }

  ngOnInit() {
    this.sensors = this.system.sensors;
    this.editForm.patchValue(this.system);
  }

  ngOnChanges = (): void => {
 //   this.prevSystem = this.system;
  }

  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  }
  onDismiss() {
    this.dialogRef.close(false);
  }
  onSubmit = async () => {
    this.system.maintenance = this.f.maintenance.value;

    this.systemService.systemUpdate(this.system)
      .pipe(first()).subscribe(
      (data: any) => {
        console.log('System update system successful', true);
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );



    // stop here if form is invalid
    this.dialogRef.close(false);
  }

}
