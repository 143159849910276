import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

const DEFAULT_SKIP = 0;
const DEFAULT_TAKE = 1000;

@Injectable({
  providedIn: 'root'
})

export class RoleService {
  public httpOptions = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });

  constructor(public http: HttpClient) {
  }

  // ROLE

  public createRole(body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/role/create', body, {headers: this.httpOptions});
  }

  public getRoleList(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/role/list', {headers: this.httpOptions});
  }

  public deleteRole(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/role/delete/' + id.toString(), {headers: this.httpOptions});
  }

  public updateRole(id: number, body: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/role/update/' + id, body, {headers: this.httpOptions});
  }

  public get(id: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/role/get/' + id, {headers: this.httpOptions});
  }
}
