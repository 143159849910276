export const frTranslate = {
  user: 'Utilisateurs',
  id: 'ID',
  name: 'Nom',
  actions: 'Actions',
  systems: 'Systèmes',
  sn: 'Numéro de série',
  pn: 'Numéro d\'article',
  newDevice: 'Nouveau système',
  validate: 'Valider',
  cancel: 'Annuler',
  mac: 'Adresse Mac',
  infos: 'Informations',
  measures: 'Mesures',
  supervision: 'Supervision',
  config: 'Configuration',
  info: 'Information',
  STATUS: 'Status',
  batterie: 'Batterie',
  memory: 'Mémoire',
  network: 'Adresse IP',
  model: 'Modèle',
  ref: 'Référence',
  version: 'Version',
  captor: 'Capteur',
  captors: 'Capteurs',
  loc: 'Localisation',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  stateE: 'Etat',
  newMesure: 'Nouvelle mesure',
  radar: 'Radar',
  fileSystem: 'Fichiers systèmes',
  mountOn: 'Partition monté sur',
  procTemp: 'Température du procésseur',
  availableMemory: 'Mémoire disponible',
  usedMemory: 'Mémoire utilisée',
  advanced: 'Avancé',
  startDiag: 'Débuter le diagnostique',
  stopDiag: 'Arrêter le diagnostique',
  graphDraw: 'Représentation graphique',
  data: 'Données',
  VOLT: 'Tension',
  TEMP: 'Température',
  PRESS: 'Pression',
  RH: 'Humidité',
  SPEED: 'Vitesse du vent',
  CSPEED: 'Vitesse du vent corrigée',
  DIR: 'Direction du vent',
  CDIR: 'Direction du vent corrigée',
  GPSLOCATION: 'Localisation GPS',
  DEWPOINT: 'Point de rosé',
  ipv4: 'Adresse IPV4',
  system: 'Système',
  weather: 'Station météo',
  fake: 'Fake',
  currentMarin: 'Courantomètre',
  newParam: 'Nouveau paramètre',
  Category: 'Catégorie',
  unitType: 'Unité',
  string: 'Chaine de caractère',
  number: 'Nombre',
  bool: 'Booleen',
  communication: 'Communication',
  measure: 'Mesure',
  storage: 'Stockage',
  newMeasureSession: 'Nouvelle session de mesure',
  sensorConfig: 'Configurations capteurs',
  newRule: 'Nouvelle règle',
  localStorage: 'Stockage fichiers locaux',
  date: 'Date',
  value: 'Valeur',
  comment: 'Commentaire',
  newStorageRule: 'Nouvelle règle de stockage',
  periodEchant: 'Période d\'échantillonage',
  numberEchantToKeep: 'Nombre d\'échantillons à conserver',
  numberTotalEchant: 'Nombre total d\'échantillons',
  defaultActivation: 'Règle activée par défaut',
  averageCalc: 'Calculer la moyenne',
  deviationCalc: 'Calculer l\'écart type',
  centrateMeasure: 'Mesure centrée à l\'échéance',
  HEIGHT: 'Hauteur d\'eau',
  cpuTemp: 'Température CPU (°C)',
  ramUsed: 'Mémoire disponible (ko)',
  batLevl: 'Tension de batterie (V)',
  freeMem: 'Mémoire utilisé (%)',
  ImxVolt: 'Tension de la SOM (V)' ,
  CUR_SPEED: 'Vitesse du courant',
  calcRule: 'Règle de calcul',
  measureEvery: ' mesures toutes les ',
  TIME: 'Date',
  CDIR_normal: 'Direction du vent moyenné',
  TEMP_short: 'Temperature moyennée',
  batLevel :'Batterie',
  viewData: 'Visualisation des données',
  state: 'Etat du système',
  chooseDate: 'Choississez la plage d\'observation de la mesure :',
  plageDate: 'Plage de mesure',
  type: 'Type de donnée',
  timeline: 'Activer la chronologie',
  graphMeasure: 'Graphique de mesure',
  connect: 'Connecté',
  disconnect: 'Déconnecté',
  status_websocket: 'Status Websocket :',
  shutdown_title: 'Extinction du système ?',
  shutdown_message: 'Attention. Après extinction, le système ne pourra pas être redémarré à distance.',
  reboot_title: 'Redémarrage du système ?',
  reboot_message: 'Après redémarrage, le système ne sera pas accessible pendant environ 1 minute.',
  format:'Format',
  lastSync:'Dernière synchro.',
  download :'Télécharger',
  sensor: 'Capteurs',
  sensorSelect: 'Capteurs sélectionnées',
  // Formulaire Exportation
  formatForm: 'Le type de format est obligatoire',
  dataForm: 'Un type de donnée est nécessaire',
  dateFrom: 'Une date de début et fin sont obligatoires',
  dateAskForm: 'Exportation de l\'ensemble des données ',
  sensorName :'Capteur',
  measureType :'Type de mesure',
  acquisitionPeriod : 'Période(ms)',
  computeSample :'Nb échantillons retennus',
  sampleCount:'Nb total échantillons',
  clearConsole: 'Effacer console',
  connectSocket :'Connecter',
  statisticProcessType: 'Opération statistique',
  show: 'Montrer',
  remove: 'Retirer',
  delete: 'Supprimer',
  stop: 'Stop',
  stopMeasure: 'Etes vous certain de vouloir arrêter la mesure ?',
  confirmStop: 'Confirmer l\'arrêt',
  battery_low: 'Batterie faible',
  samplePeriod : 'Période d\'échantillonnage (ms)',
  integrateTime :'Durée d\'intégration',
  aborted: 'stop'
};

export const enTranslate = {
  user: 'Users',
  id: 'ID',
  name: 'Name',
  actions: 'Actions',
  systems: 'Systems',
  sn: 'Serial number',
  pn: 'Part number',
  newDevice: 'New system',
  validate: 'Validate',
  cancel: 'Cancel',
  mac: 'Mac address',
  infos: 'Informations',
  measures: 'Measures',
  supervision: 'Supervision',
  config: 'Configuration',
  info: 'Information',
  STATUS: 'Status',
  batterie: 'Battery',
  memory: 'Memory',
  network: 'IP address',
  model: 'Model',
  ref: 'Reference',
  version: 'Version',
  captor: 'Sensor',
  captors: 'Sensors',
  loc: 'Location',
  startDate: 'Start date',
  endDate: 'End date',
  stateE: 'State',
  newMesure: 'New measurement',
  radar: 'Radar',
  fileSystem: 'System files ',
  mountOn: 'Partition mounted on',
  procTemp: 'Processor temperature',
  availableMemory: 'Available memory',
  usedMemory: 'Memory used',
  advanced: 'Advanced',
  startDiag: 'Start the diagnostic',
  stopDiag: 'Stop the diagnostic',
  graphDraw: 'Graphic Representation',
  data: 'Data',
  VOLT: 'Voltage',
  TEMP: 'Temperature',
  PRESS: 'Pressure',
  RH: 'Humidity',
  SPEED: 'Wind speed',
  CSPEED: 'Corrected wind speed',
  DIR: 'Wind direction',
  CDIR: 'Corrected wind direction',
  GPSLOCATION: 'GPS location',
  DEWPOINT: 'DewPoint',
  ipv4: 'IPV4 address ',
  system: 'System',
  weather: 'Weather Station',
  fake: 'Fake',
  currentMarin: 'Water Current',
  waterCurrentCpator: ' Water Current Sensor',
  newParam: 'New setting',
  Category: 'Category',
  unitType: 'Unit',
  string: 'String',
  number: 'Number',
  bool: 'Boolean',
  communication: 'Communication',
  storage: 'Storage',
  newMeasureSession: 'New measurement session',
  sensorConfig: 'Sensor configurations',
  newRule: 'New rule',
  localStorage: 'Local storage',
  date: 'Date',
  value: 'Value',
  comment: 'Comment',
  newStorageRule: 'New storage rule',
  periodEchant: 'Sampling period',
  numberEchantToKeep: 'Number of samples to keep',
  numberTotalEchant: 'Total number of samples',
  defaultActivation: 'Rule enabled by default',
  averageCalc: 'Calculate the average',
  deviationCalc: 'Calculate standard deviation',
  centrateMeasure: 'Measure centered at maturity',
  HEIGHT: 'Water height',
  cpuTemp: 'CPU temperature (°C)',
  ramUsed: 'Free memory (kB)',
  batLevl: 'Battery level (V)',
  freeMem: 'Mémoire utilisée (%)',
  ImxVolt: 'SOM Voltage (V)',
  CUR_SPEED: 'Current speed',
  calcRule: 'Calculation rule',
  measureEvery: 'measures every',
  TIME: 'Date',
  CDIR_normal: 'Wind direction averaged',
  TEMP_short: 'Average temperature',
  batLevel :'Battery',
  viewData: 'Data visualization',
  state: 'System status',
  chooseDate: 'Choose the observation range of the measurement:',
  plageDate: 'Measuring range',
  type: 'Data type',
  timeline: 'Activate the timeline',
  graphMeasure: 'Measurement graph',
  connect: 'Connect',
  disconnect: 'Disconnect',
  status_websocket: 'Websocket Status :',
  shutdown_title: 'Do you want to shutdown the system ?',
  shutdown_message: 'Be careful. The system will not be able to restart remotely.',
  reboot_title: 'Do you want to restart the system ?',
  reboot_message: 'After restarting, the system will not be available for 1 minute.',
  format:'Format',
  lastSync:'Last sync date',
  download :'Download',
  sensor: 'Sensors',
  // Formulaire Exportation
  sensorSelect: 'Selected sensor',
  formatFrom: 'Format is required',
  dataFrom: 'A minimum of one data is required',
  dateFrom: 'Date is required',
  dateAskForm: 'All data exported',
  sensorName :'Sensor',
  measureType :'Measure type',
  acquisitionPeriod : 'Period (ms)',
  computeSample :'Sample count',
  sampleCount:'Total sample count',
  clearConsole: 'Clear',
  connectSocket :'Connect',
  statisticProcessType: 'Statistic process',
  show: 'Show',
  remove: 'Remove',
  delete: 'Delete',
  stop: 'Stop',
  stopMeasure: 'Do you want to stop?',
  confirmStop: 'Confirmation',
  battery_low: 'Low battery',
  samplePeriod : 'Sample period (ms)',
  integrateTime :'Integrating time',
  aborted: 'stop'
};

export const deTranslate = {
  user: 'Benutzer',
  id: 'ID',
  name: 'Name',
  actions: 'Lager',
  systems: 'Systeme',
  newDevice: 'Neues System',
  validate: 'Validate',
  cancel: 'Stornieren',
  mac: 'Mac-Adresse ',
  infos: 'Informationen',
  measures: 'Treppe',
  supervision: 'Aufsicht',
  config: 'Konfiguration',
  info: 'Informationen',
  STATUS: 'Zustand',
  batterie: 'Batterie',
  memory: 'Speicher',
  network: 'Netzwerk',
  model: 'Modell',
  ref: 'Referenz',
  version: 'Version',
  captor: 'Sensor',
  captors: 'Sensoren',
  loc: 'Lage',
  startDate: 'Startdatum',
  endDate: 'Enddatum',
  stateE: 'Zustand',
  newMesure: 'Neue Messung',
  radar: 'Radar',
  fileSystem: 'Systemdateien',
  mountOn: 'Trennwand montiert auf',
  procTemp: 'Prozessortemperatur',
  networks: 'Netzwerke',
  availableMemory: 'Verfügbarer Speicher',
  usedMemory: 'Speicher verwendet',
  advanced: 'Fortgeschritten',
  startDiag: 'Diagnose starten',
  stopDiag: 'Beenden Sie die Diagnose',
  graphDraw: 'Grafische Darstellung',
  data: 'Daten',
  VOLT: 'Spannung',
  TEMP: 'Temperatur',
  PRESS: 'Druck',
  RH: 'Feuchtigkeit',
  SPEED: 'Windgeschwindigkeit',
  CSPEED: 'Windgeschwindigkeit korrigiert',
  DIR: 'Windrichtung',
  CDIR: 'Korrigierte Windrichtung',
  GPSLOCATION: 'GPS-Standort',
  DEWPOINT: 'Taupunkttemperatur',
  ipv4: 'IPV4-Adresse ',
  system: 'System',
  weather: 'Wetterstation',
  fake: 'Fake',
  currentMarin: 'Strömungs',
  newParam: 'Neue Einstellung',
  Category: 'Kategorie',
  unitType: 'Einheit',
  string: 'Zeichenkette',
  number: 'Anzahl',
  bool: 'Boolescher Wert',
  communication: 'Kommunikation',
  measure: 'Maßnahme',
  ident: 'Login',
  update: 'Bearbeiten',
  firstName: 'Vorname',
  newRole: 'Neue Rolle',
  rules: 'Regeln',
  newAccount: 'Kontoerstellung',
  password: 'Passwort',
  confirmPassword: 'Passwort bestätigen',
  editProfil: 'Profil bearbeiten',
  storage: 'Lagerung',
  newMeasureSession: 'Neue Messsitzung',
  sensorConfig: 'Sensorkonfigurationen',
  newRule: 'Neue Regel',
  localStorage: 'Lokale Dateispeicherung',
  date: 'Datum',
  value: 'Wert',
  rightsManagement: 'Rechteverwaltung',
  comment: 'Kommentar',
  newStorageRule: 'Neue Speicherregel',
  periodEchant: 'Probenahmezeitraum',
  numberEchantToKeep: 'Anzahl der Proben, die aufbewahrt werden sollen',
  numberTotalEchant: 'Gesamtzahl der Proben',
  defaultActivation: 'Regel standardmäßig aktiviert',
  averageCalc: 'Berechnen Sie den Durchschnitt',
  deviationCalc: 'Berechnen Sie die Standardabweichung',
  centrateMeasure: 'Maßnahme zum Zeitpunkt der Fälligkeit zentriert',
  HEIGHT: 'Wasserhöhe',
  CUR_SPEED: 'Aktuelle Geschwindigkeit',
  calcRule: 'Berechnungsregel',
  measureEvery: 'misst jeden',
  TIME: 'Datiert',
  CDIR_normal: 'Windrichtung gemittelt',
  TEMP_short: 'Durchschnittstemperatur',
  viewData: 'Datenvisualisierung',
  state: 'Systemstatus',
  chooseDate: 'Wählen Sie den Beobachtungsbereich der Messung:',
  plageDate: 'Messbereich',
  type: 'Datentyp',
  timeline: 'Aktivieren Sie die Timeline',
  graphMeasure: 'Messkurve',
};

export const esTranslate = {
  user: 'Usuarios',
  id: 'ID',
  name: 'Apellido',
  actions: 'Valores',
  systems: 'Sistemas',
  newDevice: 'Nuevo sistema',
  validate: 'Validar',
  cancel: 'Anular',
  mac: 'Dirección Mac',
  infos: 'Información',
  measures: 'Pasos',
  supervision: 'Supervisión',
  config: 'Configuración',
  info: 'Información',
  STATUS: 'Estatus',
  batterie: 'Batería',
  memory: 'Memoria',
  network: 'Redes',
  model: 'Modelo',
  ref: 'Referencia',
  version: 'Versión',
  captor: 'Sensor',
  captors: 'Sensores',
  loc: 'Ubicación',
  startDate: 'Fecha de inicio ',
  endDate: 'Fecha final ',
  stateE: 'Estado',
  newMesure: 'Nueva medida',
  radar: 'Radar',
  fileSystem: 'Archivos del sistema ',
  mountOn: 'Partición montada en',
  procTemp: 'Temperatura del procesador  ',
  networks: 'Redes',
  availableMemory: 'Memoria disponible',
  usedMemory: 'Memoria utilizada',
  advanced: 'Avanzado',
  startDiag: 'Comience el diagnóstico ',
  stopDiag: 'Detener el diagnóstico',
  graphDraw: 'Representación gráfica',
  data: 'Datos',
  VOLT: 'Voltaje',
  TEMP: 'Temperatura',
  PRESS: 'Presión',
  RH: 'Humedad',
  SPEED: 'Velocidad del viento',
  CSPEED: 'Velocidad del viento corregida',
  DIR: 'Dirección del viento ',
  CDIR: 'Dirección del viento corregida',
  GPSLOCATION: 'Ubicación GPS',
  DEWPOINT: 'Punto de rosa',
  ipv4: 'Dirección IPV4 ',
  system: 'Sistema',
  weather: 'Estación meteorológica ',
  fake: 'Fake',
  currentMarin: 'Corriente de agua',
  newParam: 'Nueva configuración',
  Category: 'Categoría',
  unitType: 'Unidad',
  string: 'Cadena de carácteres',
  number: 'Número',
  bool: 'Booleano',
  communication: 'Comunicación',
  measure: 'Medida',
  ident: 'Login',
  update: 'Editar',
  firstName: 'Primer nombre ',
  newRole: 'Nuevo rol',
  rules: 'Normas',
  newAccount: 'Creación de cuenta ',
  password: 'Contraseña',
  confirmPassword: 'Confirmar la contraseña',
  editProfil: 'Editar perfil',
  storage: 'Almacenamiento',
  newMeasureSession: 'Nueva sesión de medición',
  sensorConfig: 'Configuraciones de sensores',
  newRule: 'Nueva regla',
  localStorage: 'Almacenamiento local de archivos',
  date: 'Fecha',
  value: 'Valor',
  rightsManagement: 'Gestión de derechos',
  comment: 'Comentario',
  newStorageRule: 'Nueva regla de almacenamiento',
  periodEchant: 'Periodo de muestreo',
  numberEchantToKeep: 'Número de muestras para guardar',
  numberTotalEchant: 'Numero total de muestras',
  defaultActivation: 'Regla habilitada por defecto',
  averageCalc: 'Calcular el promedio',
  deviationCalc: 'Calcular desviación estándar',
  centrateMeasure: 'Medida centrada en la madurez',
  HEIGHT: 'Altura del agua',
  CUR_SPEED: 'Velocidad actual',
  calcRule: 'Regla de cálculo',
  measureEvery: 'mide cada',
  TIME: 'Fechado',
  CDIR_normal: 'Dirección del viento promediada',
  TEMP_short: 'Temperatura media',
  viewData: 'Visualización de datos',
  state: 'Estado del sistema',
  chooseDate: 'Elija el rango de observación de la medición:',
  plageDate: 'Rango de medición',
  type: 'Tipo de datos',
  timeline: 'Activa la línea de tiempo',
  graphMeasure: 'Gráfico de medición',
};
