import {Component, Input, OnInit, Output} from '@angular/core';
import {Role} from '../../userRole/_model/role';
import {StorageRule} from '../_model/StorageRule';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-storage-rule-list',
  templateUrl: './storage-rule-list.component.html',
  styleUrls: ['./storage-rule-list.component.css']
})
export class StorageRuleListComponent implements OnInit {
  @Input() storageRuleEntities: StorageRule[];
  @Input() control: FormControl;
  check: any;

  constructor() { }

  ngOnInit() {
  }

  onNgModelChange($event: any) {
    this.control.setValue( $event);
  }
}
