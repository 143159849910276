import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {GlobalEventsManager} from '../../_helpers';
import {UserService} from '../user/_service/user.service';
import {User} from '../user/_model/user';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

export class NavigationComponent implements OnDestroy {

  userConnected = false;
  isAdmin = false;
  currentUser: User = null;

  constructor(public http: HttpClient,
              public router: Router,
              public globalEventsManager: GlobalEventsManager,
              public userService: UserService,
              public translate: TranslateService
  ) {
    try {
      this.globalEventsManager.loginEvent.subscribe((mode: any): void => {
        this.userConnected = mode;
        // get only the Menu for which current logged in User has the access.
        if (this.userConnected === true) {
          const loginResponse = JSON.parse(localStorage.getItem('currentUser'));
          this.currentUser = loginResponse.user;
          this.isAdmin = (this.currentUser.role.roleName.toLowerCase() === 'admin');
        }
      });
    } catch {
      this.router.navigateByUrl('/user/login');
    }
  }

  btnClick = (dest: string): void => {
    if (this.currentUser) {
      this.router.navigateByUrl('/' + dest);
    } else {
      this.router.navigateByUrl('/user/login');
    }
  }

  logout = (): void => {
    this.userService.logout();
    this.userConnected = false;
    this.currentUser = null;
    this.router.navigateByUrl('/user/login');
  }

  ngOnDestroy(): void {
    localStorage.removeItem('currentUser');
  }
}
