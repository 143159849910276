import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';


export interface Format {
  value: string;
  viewValue: string;
  mimeType: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {


  public httpOptions = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/zip',
    'Access-Control-Allow-Origin': '*'
  });

  constructor(public http: HttpClient) {
  }

  public getZip(measureSessionId : number, idList: number[], startDate: Date, endDate: Date, type: Format, unit?: string) {

    const body = {
      measureSessionId,
      startDate,
      endDate,
      idList,
      type,
      unit
    };


   return   this.http.post<any>(environment.apiUrl + '/export/create',  body, {headers: this.httpOptions});
  }
}
