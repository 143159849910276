import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

import {Observable, of} from 'rxjs';
import {MeasureSession} from '../_model/measureSession';

@Injectable({
  providedIn: 'root'
})
export class MeasureSessionService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(public http: HttpClient) {
  }

  public measureSessionList(id: number) {
    return this.http.get<any>(environment.apiUrl + '/measureSession/list/' + id, this.httpOptions);
  }

  public measureSessionDelete(id: number) {
    console.log('measureSessionDelete   ' + id);
    return this.http.delete<any>(environment.apiUrl + '/measureSession/delete/' + id, this.httpOptions);
  }

  public measureSessionCreate(idSystem: number, session: MeasureSession) {
    return this.http.post<any>(environment.apiUrl + '/measureSession/create/' + idSystem, JSON.stringify(session), this.httpOptions);
  }

  public getSession(idSystem: number, idSession: number) {
    return this.http.get<any>(environment.apiUrl + '/measureSession/get/' + idSession, this.httpOptions);
  }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
