export const frNavigationTranslate = {
  navigation_connect: 'Connexion',
  navigation_systems: 'Systèmes',
  navigation_home: 'Accueil',
  navigation_param: 'Paramètres',
  navigation_myAccount: 'Mon compte',
  navigation_user: 'Utilisateurs',
  navigation_deconnect: 'Déconnexion',
};

export const enNavigationTranslate = {
  navigation_connect: 'Log in',
  navigation_systems: 'Systems',
  navigation_home: 'Home',
  navigation_param: 'Settings',
  navigation_myAccount: 'My account',
  navigation_user: 'Users',
  navigation_deconnect: 'Sign Out',
};

export const deNavigationTranslate = {
  navigation_connect: 'Einloggen',
  navigation_systems: 'Systeme',
  navigation_param: 'Einstellungen',
  navigation_myAccount: 'Mein Konto',
  navigation_user: 'Benutzer',
  navigation_deconnect: 'Austragen',
};

export const esNavigationTranslate = {
  navigation_connect: 'Acceder',
  navigation_systems: 'sistemas',
  navigation_param: 'Configuraciones',
  navigation_myAccount: 'Mi cuenta',
  navigation_user: 'Usuarios',
  navigation_deconnect: 'Salir',
};
