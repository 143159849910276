export const frUserTranslate = {
  user_user: 'Utilisateurs',
  user_id: 'ID',
  user_name: 'Nom',
  user_mail: 'Email',
  user_phone: 'Numéro de téléphone',
  user_role: 'Rôle',
  user_actions: 'Actions',
  user_connect: 'Connexion',
  user_manageRole: 'Gérer les droits',
  user_validate: 'Valider',
  user_cancel: 'Annuler',
  user_update: 'Editer',
  user_firstName: 'Prénom',
  user_forgetPassword: 'Mot de passe oublié ?',
  user_newAccount: 'Création de compte',
  user_password: 'Mot de passe',
  user_confirmPassword: 'Confirmer mot de passe',
  user_editProfil: 'Éditer le profil',
  user_confirmDelete: 'Confirmer la suppression',
  user_accountDelete: 'Etes-vous certain de vouloir supprimer le compte de ',
  user_forgetPass: 'Mot de passe oublié',
  user_textPopUpPassword: 'Renseignez votre adresse mail ci-dessous.',
  remove_system: 'Etes-vous certain de vouloir supprimer ce système définitivement ?',
  remove_systemFromUserList: 'Etes-vous certain de vouloir retirer ce système de votre liste ?',
  remove_object: 'Etes vous certain de vouloir supprimer ?',
  confirm_systemFromUserList: 'Retirer de la liste',
  confirmDelete: 'Confirmer la suppression',
  user_show: 'Montrer',
  user_delete: 'Supprimer',
  user_change_role: 'Changer le role',
  user_edit: 'Edition',
  user_lastName_required: 'Votre nom est requis',
  user_firstName_required: 'Votre prénom est requis',
  user_mail_required: 'Veuillez saisir une adresse email',
  user_mail_already: 'Cette adresse email est déjà utilisée',
  user_phone_required: 'Numéro de téléphone requis',
  user_phone_incorrect: 'Le numéro de téléphone n\'est pas correct',
  user_password_required: 'Veuillez saisir un mot de passe valide',
  user_password_noNumber: 'Le mot de passe saisi ne contient pas de numéro',
  user_password_noUpper: 'Le mot de passe saisi ne contient pas de majuscule',
  user_password_noLower: 'Le mot de passe saisi ne contient pas de minuscule',
  user_password_noLonger: 'Le mot de passe saisi est trop court (8 caractères minimum)',
  user_password_noSpecialCharac: 'Le mot de passe saisi ne doit pas contenir de charactères spéciaux',
  user_confirmPassword_required: 'Les mots de passe ne sont pas identiques'
};

export const enUserTranslate = {
  user_user: 'Users',
  user_id: 'ID',
  user_name: 'Name',
  user_mail: 'E-mail',
  user_phone: 'Phone number',
  user_role: 'Role',
  user_actions: 'Actions',
  user_connect: 'Log in',
  user_manageRole: 'Manage rights',
  user_validate: 'Validate',
  user_cancel: 'Cancel',
  user_update: 'Edit',
  user_firstName: 'First name',
  user_forgetPassword: 'Forgot your password ?',
  user_newAccount: 'Account creation',
  user_password: 'Password',
  user_confirmPassword: 'Confirm password',
  user_editProfil: 'Edit profile',
  user_confirmDelete: 'Confirm deletion',
  user_accountDelete: 'Are you sure you want to delete the account from ',
  user_forgetPass: 'Forgot your password',
  user_textPopUpPassword: 'Enter your email address below.',
  remove_system: 'Are you sure you want to delete this system?',
  remove_systemFromUserList: 'Are you sure you want to remove this system?',
  remove_object: 'Are you sure you want to delete this ?',
  confirm_systemFromUserList: 'Delete from the list',
  confirmDelete: 'Confirm deletion',
  user_show: 'Show',
  user_delete: 'Delete',
  user_change_role: 'Change role',
  user_edit: 'Edit',
  user_lastName_required: 'Your last name is required',
  user_firstName_required: 'Your first name is required',
  user_mail_required: 'Your email is required',
  user_mail_already: 'This email has already an account',
  user_phone_required: 'A phone number is required',
  user_phone_incorrect: 'This phone number is not correct',
  user_password_required: 'A password is required',
  user_password_noNumber: 'There is no number',
  user_password_noUpper: 'There is no upper case',
  user_password_noLower: 'There is no lower case',
  user_password_noLonger: 'The password is too short (8 characters minimum)',
  user_password_noSpecialCharac: 'The password should not contain special character',
  user_confirmPassword_required: 'Passwords are not the same'
};

export const deUserTranslate = {
  user_user: 'Benutzer',
  user_id: 'ID',
  user_name: 'Name',
  user_mail: 'E-Mail',
  user_phone: 'Telefonnummer',
  user_role: 'Rolle',
  user_actions: 'Lager',
  user_connect: 'Einloggen',
  user_manageRole: 'Rechte verwalten',
  user_validate: 'Validate',
  user_cancel: 'Stornieren',
  user_update: 'Bearbeiten',
  user_firstName: 'Vorname',
  user_forgetPassword: 'Passwort vergessen?',
  user_newAccount: 'Kontoerstellung',
  user_password: 'Passwort',
  user_confirmPassword: 'Passwort bestätigen',
  user_editProfil: 'Profil bearbeiten',
  user_confirmDelete: 'Bestätigen Sie den Löschvorgang',
  user_accountDelete: 'Möchten Sie das Konto wirklich von löschen ',
  user_forgetPass: 'Passwort vergessen',
  user_textPopUpPassword: 'Geben Sie unten Ihre E-Mail-Adresse ein.',
  remove_system: 'Möchten Sie dieses System wirklich löschen?',
  confirmDelete: 'Bestätigen Sie den Löschvorgang',
  remove_object: 'Möchten Sie dies wirklich löschen?',
};

export const esUserTranslate = {
  user_user: 'Usuarios',
  user_id: 'ID',
  user_name: 'Apellido',
  user_mail: 'E-mail',
  user_phone: 'Número de teléfono',
  user_role: 'Derecho',
  user_actions: 'Valores',
  user_connect: 'Acceder',
  user_manageRole: 'Administrar derechos',
  user_validate: 'Validar',
  user_cancel: 'Anular',
  user_update: 'Editar',
  user_firstName: 'Primer nombre ',
  user_forgetPassword: 'Contraseña olvidada ?',
  user_newAccount: 'Creación de cuenta ',
  user_password: 'Contraseña',
  user_confirmPassword: 'Confirmar la contraseña',
  user_editProfil: 'Editar perfil',
  user_confirmDelete: 'Confirmar eliminación',
  user_accountDelete: '¿Está seguro de que desea eliminar la cuenta de',
  user_forgetPass: 'Contraseña olvidada',
  user_textPopUpPassword: 'Ingrese su dirección de correo electrónico a continuación.',
  remove_system: '¿Seguro que quieres eliminar este sistema?',
  confirmDelete: 'Confirmar eliminación',
  remove_object: '¿Estás segura de que quieres eliminar esto?',
};
