import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoleEditComponent} from './role-edit/role-edit.component';
import {RoleCreateComponent} from './role-create/role-create.component';
import {RoleListComponent} from './role-list/role-list.component';
import {RoleViewComponent} from './role-view/role-view.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AuthGuard} from '../../_guards';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {deRoleTranslate, enRoleTranslate, esRoleTranslate, frRoleTranslate} from './_translation/translate';
import {RuleListComponent} from './rule-list/rule-list.component';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTreeModule} from '@angular/material/tree';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

export const UserRoleRoute: any[] = [
  {path: '', component: RoleListComponent},
  {path: 'create', component: RoleCreateComponent, canActivate: [AuthGuard]},
  {path: 'list', component: RoleListComponent, canActivate: [AuthGuard]},
  {path: 'update/:id', component: RoleEditComponent, canActivate: [AuthGuard]},
  {path: 'view/:id', component: RoleViewComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatFormFieldModule,
    MatTreeModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatSortModule,
    MatSelectModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    RoleCreateComponent,
    RoleEditComponent,
    RoleListComponent,
    RoleViewComponent,
    RuleListComponent
  ],
  exports: [
    RoleCreateComponent,
    RoleEditComponent,
    RoleListComponent,
    RoleViewComponent,
  ],
})
export class UserRoleModule {
  constructor(translate: TranslateService) {
    translate.setTranslation('en', enRoleTranslate, true);
    translate.setTranslation('de', deRoleTranslate, true);
    translate.setTranslation('fr', frRoleTranslate, true);
    translate.setTranslation('es', esRoleTranslate, true);
  }
}
