import {MeasureSession} from '../component/measure/_model/measureSession';
import {System} from '../component/system/_model/System';

export const TYPE_CMD = 'cmd';
export const TYPE_SETTING = 'setting';
export const CMD_DIAG = 'diag';
export const CMD_STOP = 'stop';
export const CMD_TURNOFF = 'turnOff';
export const CMD_RESTART = 'restart';
export const CMD_ADDMEASURE = 'add';
export const CMD_EDITMEASURE = 'edit';
export const CMD_RMMEASURE = 'remove';

export class EponimCmd {
  public type: string;
  public action: string;
  public measure: MeasureSession[];
  public system: System;
  public data: any;
}
