import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SensorMeasureSetting} from '../../measure/_model/sensorMeasureSetting';
import {Sensor} from '../../system/_model/Sensor';
import {System} from '../../system/_model/System';
import { hashCode} from '../../../utils/commonTools';
import {MatSelectChange} from '@angular/material/select';
import {first} from 'rxjs/operators';
import {MeasureSession} from '../_model/measureSession';
import {SensorMeasureSettingService} from '../../system/_service/sensorMeasureSetting.service';
import {ActivatedRoute} from '@angular/router';
import {MeasureSessionService} from '../_service/measureSession.service';
import {SpinnerService} from '../../../_services/spinner.service';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';

@Component({
  selector: 'app-measure-data-viewer',
  templateUrl: './measure-data-viewer.component.html',
  styleUrls: ['./measure-data-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class MeasureDataViewerComponent implements OnChanges, OnInit {
  @Input() system: System;
  @Input() measureSession: MeasureSession;
  @Input() loading = false;
  @Input() diagMode = true;

  public startDate: Date;
  public endDate: Date;
  public selectedSensor: Sensor[] = [];
  public dataLoading: boolean;
  public newDataReceived: EventEmitter<any> = new EventEmitter();

  constructor(public activatedRoute: ActivatedRoute,
              public measureSessionService: MeasureSessionService,
              public spinnerService: SpinnerService,
              public eponimWebSocketService: EponimWebSocketService,
              public sensorMeasureSettingService: SensorMeasureSettingService) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log('MeasureDataViewerComponent');
    await this.dateChange();
  }

  async ngOnInit(): Promise<void> {
    this.dataLoading = true;
    this.loadData();
  }

  async dateChange() {
    if (!this.diagMode && this.measureSession) {
      this.dataLoading = true;

      for (const sensorMeasureSetting of this.measureSession.sensorMeasureSettingEntities) {
        await this.sensorMeasureSettingService.getMeasureBySensorMeasureSetting(sensorMeasureSetting.id, this.startDate, this.endDate)
          .pipe(first()).subscribe(async (data: any) => {
            this.dataLoading = false;
            sensorMeasureSetting.sensorMeasures = [];
            if (data.success) {
              if (data.data) {
                if (!sensorMeasureSetting.sensorMeasures) {
                  sensorMeasureSetting.sensorMeasures = [];
                }
                if (data.data.sensorMeasures.length > 0) {
                  sensorMeasureSetting.sensorMeasures = sensorMeasureSetting.sensorMeasures.concat(data.data.sensorMeasures);
                  this.newDataReceived.emit(null);
                }
              }
            }
          });
      }
    }else{
      this.dataLoading = true;

    }
  }

  public loadData() {
    if (!this.diagMode && this.measureSession) {
      this.startDate = new Date(this.measureSession.startDate);
      //this.startDate = new Date(new Date(this.measureSession.startDate).getTime() - 60*60*1000);
      this.endDate = new Date(this.measureSession.endDate);
      this.dateChange();
    }
    if(this.diagMode){
      this.measureSession= this.eponimWebSocketService.diadMeasureSession;
    }
  };

  public getColorFrom(str: string) {
    return hashCode(str);
  }

  selected($event: MatSelectChange) {
    this.selectedSensor = $event.value;
  }

  public getSensorMeasureSettingBySensor(sensor: Sensor) {
    const sensorMeasureSettings: SensorMeasureSetting[] = [];
    for (const sensorMeasureSetting of this.measureSession.sensorMeasureSettingEntities) {
      if (sensor.type === sensorMeasureSetting.sensor.type) {
        sensorMeasureSettings.push(sensorMeasureSetting);
      }
    }
    return sensorMeasureSettings;
  }
  onNewData(){
    this.newDataReceived.emit(null);
    this.measureSession= this.eponimWebSocketService.diadMeasureSession;
  }
}
