import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {SystemService} from '../../system/_service/system.service';
import {first} from 'rxjs/operators';
import {RoleService} from '../_service/role.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})

export class RoleListComponent implements OnInit {
  registerForm: FormGroup;
  routes: any[] = [];
  roleList: any[] = [];

  constructor(public systemService: SystemService,
              public roleService: RoleService,
              public router: Router,
  ) {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  }

  ngOnInit(): void {
    this.routes = [];
    this.systemService.getAllRoute()
      .pipe(first()).subscribe(
      (data: any): void => {
        for (const rule of data.data) {
          for (const method of rule.methods) {
            this.routes.push(method + ' : ' + rule.path);
          }
        }
        for (const route of this.routes) {
          this.registerForm.addControl(route, new FormControl('', [Validators.required]));
        }
        this.routes.sort();
      },
      (): void => {
        this.routes = null;
      }
    );

    this.roleService.getRoleList()
      .pipe(first()).subscribe(
      (data: any): void => {
        this.roleList = data;
      //  console.log(JSON.stringify(this.roleList))
      },
      (): void => {
        this.roleList = [];
      }
    );
  }
}
