export const frChartTranslate = {
  chart_displayAll: 'Tout afficher',
  chart_legend: 'Légende',
};

export const enChartTranslate = {
  chart_displayAll: 'Show all',
  chart_legend: 'Legend',
};

export const deChartTranslate = {
  chart_displayAll: 'Alle anzeigen',
  chart_legend: 'Legende',
};

export const esChartTranslate = {
  chart_displayAll: 'Ver todo',
  chart_legend: 'Leyenda',
};
