import {SensorMeasureSetting} from './sensorMeasureSetting';

export class MeasureSession {
  id: number;
  state: string;
  location: string;
  startDate: Date;
  endDate: Date;
  sensorMeasureSettingEntities: SensorMeasureSetting[];
}

