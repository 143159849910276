import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Rule} from '../_model/rule';
import {SystemService} from '../../system/_service/system.service';
import {RoleService} from '../_service/role.service';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Role} from '../_model/role';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {
  editForm: FormGroup;
  loading = false;
  submitted = false;
  rules: Rule[];
  public role: Role;


  constructor(public router: Router, public activatedRoute: ActivatedRoute, public formBuilder: FormBuilder,
              public systemService: SystemService,
              public roleService: RoleService) {
    this.editForm = this.formBuilder.group({
      roleName: new FormControl('', [Validators.required]),
      rules: [null, [Validators.required]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.editForm.controls;
  }

  async ngOnInit(): Promise<void> {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    await this.roleService.get(id).pipe(first()).subscribe(
      (data: any): void => {
        this.role = data;
        this.editForm.patchValue(this.role);
      },
      (err: HttpErrorResponse): void => {
        console.log(err);
        const errMsg: string = err.error.error;
        console.log(errMsg);
      });

    await this.systemService.getAllRoute()
      .pipe(first()).subscribe(
        (data: any): void => {
          this.rules = data.data;
        },
        (err: HttpErrorResponse): void => {
          this.rules = [];
          console.log(err);
          const errMsg: string = err.error.error;
          console.log(errMsg);
        }
      );
  }

  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  }

  onSubmit = (): void => {
    this.submitted = true;

    if (this.editForm.invalid) {
      console.log('form invalid');
      console.log(this.editForm);
      return;
    } else {
      const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      this.roleService.updateRole(id, this.editForm.value).pipe(
        first()
      ).subscribe(
        (): void => {
          console.log('Edit successful', true);
          this.router.navigate(['/userRole/list']).then(r => {
            if (!r) {
              console.log('unable to access page roleList');
            }
          });
        },
        (err: HttpErrorResponse): void => {
          console.log(err);
          const errMsg: string = err.error.error;
          console.log(errMsg);
        }
      );
      this.loading = false;
    }
  }
}
