import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {User} from '../_model/user';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../_service/user.service';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogModel} from '../../shared/Pop-up/_model/ConfirmDialogModel';
import {ConfirmDialogComponent} from '../../shared/Pop-up/confirm-dialog/confirm-dialog.component';
import {PopUpChangeRoleComponent} from '../../shared/Pop-up/pop-up-change-role/pop-up-change-role.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<User>;
  size: number;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = ['id', 'lastName', 'email', 'phone', 'roleName', 'actions'];

  constructor(public router: Router, public dialog: MatDialog, public userService: UserService, public translate: TranslateService) {
  }

  ngOnInit(): void {

    this.userService.getUserList(0, 25)
      .pipe(first()).subscribe(
      (data: any): void => {
        this.dataSource = new MatTableDataSource(data.userList.series);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.size = data.userList.maxSize;
      },
      (): void => {
        this.dataSource = null;
      }
    );
  }


  btnClick = (dest: string, id: string): void => {
    this.router.navigateByUrl('/' + dest + '/' + id);
  }

  gestionRole = (): void => {
    this.router.navigateByUrl('/userRole/list');
  }

  deleteUser = (id: string, firstName: string, lastName: string): void => {
    let message = '';
    let title = '';

    this.translate.get('user_accountDelete', {value: 'user_accountDelete'}).subscribe((textMsg: string) => {
      message = textMsg + firstName + ' ' + lastName + ' ?';
    });

    this.translate.get('user_confirmDelete', {value: 'user_confirmDelete'}).subscribe((textTitle: string) => {
      title = textTitle;
    });

    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.userService.deleteUser(id).pipe(
          first()
        ).subscribe(
          (): void => {
            window.location.reload();
          },
          (err: HttpErrorResponse): void => {
            console.log(err);
          }
        );
      }
    });
  };

  changePage = (event: any): void => {
    const skip = event.pageSize * (event.pageIndex);
    const take = event.pageSize;
    this.userService.getUserList(skip, take)
      .pipe(first()).subscribe(
      (data: any): void => {
        this.dataSource = new MatTableDataSource(data.userList.series);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }


  changeRole = (row: any): void => {


    const dialogRef = this.dialog.open(PopUpChangeRoleComponent, {
      maxWidth: '400px',
      data: {firstName: row.firstName, lastName: row.lastName, roleName: row.role.roleName, newRoleName: ''}
    });

    dialogRef.afterClosed().subscribe((result: any): void => {
      if (result && row.role.roleName !== result) {
        const newRow = row;
        newRow.roleName = result;
        this.userService.updateUser(newRow.id, newRow).pipe(first()).subscribe(
          (): void => {
            this.ngOnInit();
          });
      }
    });

  }
  onSortData(sort: Sort) {
    const data =this.dataSource.data;
    if (sort.active && sort.direction !== '') {
      return data.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';
        switch (this.sort.active) {
          case 'id':
            return compare(a.id, b.id, isAsc);
          case 'lastName':
            return compare(a.lastName, b.lastName, isAsc);
          case 'email':
            return compare(a.email, b.email, isAsc);
          case 'phone':
            return compare(a.phone, b.phone, isAsc);
          case 'roleName':
            return compare(a.role.roleName, b.role.roleName, isAsc);
          default:
            return 0;
        }
      });
    }
  }
}
function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
