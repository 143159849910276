import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {SystemService} from '../_service/system.service';
import {EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class SystemDetailResolver implements Resolve<any> {

  constructor(public systemService: SystemService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params.id;
    return this.systemService.getSystem(id).pipe(
      catchError((error) => {
        return EMPTY;
      })
    );
  }
}
