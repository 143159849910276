import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(public http: HttpClient) {
  }

  public getListMeasure(idSystem: number, idSession: number, typeSensor: string, startDate: string, endDate: string) {
    let params = new HttpParams();
    params = params.append('startDate', startDate.toString());
    params = params.append('endDate', endDate.toString());
    return this.http.get<any>(environment.apiUrl + '/measure/list/' + idSystem + '/' + idSession + '/' + typeSensor,
      { headers:  this.httpOptions.headers, params });
  }

  public getLastMeasure(idSystem: number) {
    return this.http.get<any>(environment.apiUrl + '/measure/getLastState/' + idSystem ,
      { headers:  this.httpOptions.headers});
  }
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
