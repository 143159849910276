import {Component, Inject, OnInit} from '@angular/core';

import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmDialogModel} from '../_model/ConfirmDialogModel';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-input-pop-up',
  templateUrl: './input-pop-up.component.html',
  styleUrls: ['./input-pop-up.component.css']
})
export class InputPopUpComponent implements OnInit {
  forgetForm: FormGroup;
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<InputPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
              public formBuilder: FormBuilder) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgetForm.controls;
  }

  ngOnInit() {
    this.forgetForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.f.username.value);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(null);
  }
}
