import {EventEmitter, Injectable, OnDestroy, Output} from '@angular/core';
import {System} from '../component/system/_model/System';
import {MeasureSession} from '../component/measure/_model/measureSession';
import {CMD_DIAG, CMD_RESTART, CMD_RMMEASURE, CMD_STOP, CMD_TURNOFF, EponimCmd, TYPE_CMD, TYPE_SETTING} from '../_models/EponimCmd';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {delay} from '../utils/commonTools';
import {SensorMeasureSetting} from '../component/measure/_model/sensorMeasureSetting';
import {DebugMessage} from '../component/system/_model/DebugMessage';

const MAX_MEASURE_COUNT = 120;

@Injectable({
  providedIn: 'root'
})

export class EponimWebSocketService implements OnDestroy {
  @Output() newDataReceived: EventEmitter<any> = new EventEmitter();
  @Output() newLogReceived: EventEmitter<any> = new EventEmitter();
  public cmdSocket: WebSocketSubject<any>;
 // public dataSocket: WebSocketSubject<any>;
  public logSocket: WebSocketSubject<any>;
  public system: System;
  public cmdSocketOpened: boolean;
 // public dataSocketOpened: boolean;
  public messages: any[];
  public diadMeasureSession: MeasureSession = null;
  msgReceive: DebugMessage[] = [];

  public constructor() {
    this.cmdSocketOpened = false;
    this.messages = [];

  }

  ngOnDestroy(): void {
    if (this.cmdSocket != null) {
      this.cmdSocket.unsubscribe();
    }
  }

public  isConnected(system: System){
    this.checkSocketsystem(system);
    return this.cmdSocketOpened
}
  public async send(messageContent: any, timeOut: number = 100) {
    this.checkSocketsystem(this.system);

    this.messages = [];
    this.cmdSocket.next(messageContent);
    let cpt = 0;
    while ((cpt < timeOut) && (this.messages.length < 1)) {
      await delay(10);
      cpt++;
    }
    if (this.messages.length > 0) {
      this.cmdSocketOpened = true;
      return this.messages[0];
    } else {
      return '';
    }
  }

  public checkSocketsystem(system: System) {
    if (!this.system || (this.system.id !== system.id)) {
      if (this.cmdSocket) {
        this.cmdSocket.unsubscribe();
        this.cmdSocket = null;
      }
      this.initCmdSocket(system);

      this.initLogSocket(system);
    }
  }

  public async updateSystemSetting(system: System) {
    this.checkSocketsystem(system);
    const cmd = new EponimCmd();
    cmd.type = TYPE_SETTING;
    cmd.action = TYPE_SETTING;
    cmd.system = system;
    let reply;
    reply = await this.send(cmd);
    console.log('reply');
    console.log(reply);
    return reply;
  }

  public async turnOff(system: System) {
    this.checkSocketsystem(system);
    const cmd = new EponimCmd();
    cmd.type = TYPE_CMD;
    cmd.action = CMD_TURNOFF;
    let reply;
    reply = await this.send(cmd);
    console.log('reply');
    console.log(reply);
    return reply;
  }

  public async restart(system: System) {
    this.checkSocketsystem(system);
    const cmd = new EponimCmd();
    cmd.type = TYPE_CMD;
    cmd.action = CMD_RESTART;
    let reply;
    reply = await this.send(cmd);
    console.log('reply');
    console.log(reply);
    return reply;
  }

  public async launchDiagMode(system: System) {
    this.checkSocketsystem(system);
    console.log("START DIAG");
    const cmd = new EponimCmd();
    cmd.type = TYPE_CMD;
    cmd.action = CMD_DIAG;
    if (this.system.measureSessions && this.system.measureSessions.length > 0) {
      cmd.measure = [this.system.measureSessions[0]];
    }
 //   console.log(JSON.stringify(cmd));
    let reply;
    reply = await this.send(cmd);
    this.initDiagMesure();
   // this.initDataSocket(system);
    return reply;
  }

  public async stopCurrentAction(system: System) {
    this.checkSocketsystem(system);
    const cmd = new EponimCmd();
    cmd.type = TYPE_CMD;
    cmd.action = CMD_STOP;
    let reply;
    reply = await this.send(cmd);
    return reply;
  }

  public async removeMeasureSession(measureSession: MeasureSession, system: System) {
    this.checkSocketsystem(system);
    const cmd = new EponimCmd();
    cmd.type = TYPE_CMD;
    cmd.action = CMD_RMMEASURE;
    cmd.measure = [measureSession];
    let reply;
    console.log(JSON.stringify(cmd));
    reply = await this.send(cmd);

    console.log('reply');
    console.log(reply);
    return reply;
  }

  initDiagMesure(): void {
    this.diadMeasureSession = new MeasureSession();
    this.diadMeasureSession.startDate = new Date();
    this.diadMeasureSession.endDate = this.diadMeasureSession.startDate;
    this.diadMeasureSession.endDate.setFullYear(20230, 1, 1);
    this.diadMeasureSession.sensorMeasureSettingEntities = [];
  }

  public initCmdSocket(system: System): void {
    this.system = system;
    const url = 'ws://' + system.ipAddress + ':'; //+ system.wsPort;



    console.log('INIT WEBSOCKET URL : '+url);
    this.cmdSocket =
      webSocket<EponimCmd>({
        url
      });

    this.cmdSocket.subscribe(
      (message) => {
        this.cmdSocketOpened = true;
        console.log(message);
        this.messages.push(message);
if(message.hasOwnProperty('measures')) {
  this.newMeasureReceived(message.measures);
}
      },
      (err) => {
        console.log(err);
        this.initCmdSocket(system);
      },
      () => {
        this.cmdSocketOpened = true;
        console.warn('Completed!');
      }
    );
  }

  public initLogSocket(system: System): void {
    this.system = system;
    const url = 'ws://' + this.system.ipAddress + ':' + '222';
    console.log('LOG URL : '+url);
    this.logSocket =
      webSocket({
        url
      });

    this.logSocket.subscribe(
      (message) => {
        this.cmdSocketOpened = true;
        this.msgReceive=[];
        this.msgReceive= this.msgReceive.concat(message.logs);
        this.cmdSocketOpened= true;
        this.newLogReceived.emit(null);
      },
      (err) => {
        console.log(err);
      },
      () => {
        console.warn('Completed!');
      }
    );
  }

/*  public initDataSocket(system: System): void {
    this.dataSocketOpened = false;
    this.system = system;
    const url = 'ws://' + system.ipAddress + ':' + system.wsPort;
    console.log('DATA URL : '+url);
    this.dataSocket =
      webSocket<EponimCmd>({
        url
      });

    this.dataSocket.subscribe(
      (message) => {
        console.log(message);
        this.cmdSocketOpened = true;
        this.newMeasureReceived(message.measures);
      },
      (err) => {
        console.log(err);
        this.initDataSocket(system);
      },
      () => {
        this.dataSocketOpened = true;
        console.warn('Completed!');
      }
    );
  }*/

  public newMeasureReceived = (newsensorMeasureSettingEntities: SensorMeasureSetting[]) => {
    for (const sensorMeasureSetting of newsensorMeasureSettingEntities) {
      const idx = this.diadMeasureSession.sensorMeasureSettingEntities
        .findIndex((tmp) => (tmp.sensor.type === sensorMeasureSetting.sensor.type)
          && (tmp.storageRule.name === sensorMeasureSetting.storageRule.name)
          && (tmp.sensorMeasureType.measureType === sensorMeasureSetting.sensorMeasureType.measureType));

      if (idx === -1) {
        this.diadMeasureSession.sensorMeasureSettingEntities.push(sensorMeasureSetting);
      } else {
        this.diadMeasureSession.sensorMeasureSettingEntities[idx].sensorMeasures = this.diadMeasureSession.sensorMeasureSettingEntities[idx].sensorMeasures.concat(sensorMeasureSetting.sensorMeasures);
        if (this.diadMeasureSession.sensorMeasureSettingEntities[idx].sensorMeasures.length > MAX_MEASURE_COUNT) {
          this.diadMeasureSession.sensorMeasureSettingEntities[idx].sensorMeasures = this.diadMeasureSession.sensorMeasureSettingEntities[idx].sensorMeasures
            .slice(Math.max(this.diadMeasureSession.sensorMeasureSettingEntities[idx].sensorMeasures.length - MAX_MEASURE_COUNT, 1));
        }
      }
    }
    this.newDataReceived.emit(null);
  };
}
