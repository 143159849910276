import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {UserService} from '../component/user/_service/user.service';



@Injectable()
export class LogInterceptor implements HttpInterceptor {
  constructor(public userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.userService.logout();
        location.reload(true);
        console.log('Unauthorized 401');


      } else if (err.status === 422) {
        return throwError(err);
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
