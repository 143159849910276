import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {System} from '../../../_models';
import {Observable} from 'rxjs';
import { first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SystemService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(public http: HttpClient) {
  }


  public getSystem(id: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/system/get/' + id, this.httpOptions).pipe(first());
  }

  public systemList() {
    return this.http.get<any>(environment.apiUrl + '/system/list', this.httpOptions);
  }


  public systemPairing(system: System) {
    console.log('systemPairing   ' + environment.apiUrl);
    const body = {
      MAC_ADDR: system.MAC_ADDR
    };
    return this.http.put<any>(environment.apiUrl + '/system/pairing', JSON.stringify(body), this.httpOptions);
  }

  public systemUpdate(system: System) {
    const body = {
      name: system.name,
      ipAddress: system.ipAddress,
      maintenance: system.maintenance
    };
    return this.http.put<any>(environment.apiUrl + '/system/update/'+ system.MAC_ADDR, JSON.stringify(body), this.httpOptions);
  }

  public systemRemoveUser(id: number) {
    return this.http.get<any>(environment.apiUrl + '/system/removeUser/' + String(id), this.httpOptions);
  }

  public systemRemove(id: number) {
    return this.http.delete<any>(environment.apiUrl + '/system/delete/' + String(id), this.httpOptions);
  }
  public getAllRoute(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/help', this.httpOptions);
  }
}
