import {Component, Input, OnInit} from '@angular/core';
import {Role} from '../_model/role';
import {RoleService} from '../_service/role.service';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogModel} from "../../shared/Pop-up/_model/ConfirmDialogModel";
import {ConfirmDialogComponent} from "../../shared/Pop-up/confirm-dialog/confirm-dialog.component";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent implements OnInit {
  @Input() role: Role;

  constructor(public dialog: MatDialog, public router: Router, public roleService: RoleService, public translate: TranslateService) {
  }

  ngOnInit() {

  }

  removeRole() {
    let message = '';
    let title = '';

    this.translate.get('userRole_confirmDelete', {value: 'userRole_confirmDelete'}).subscribe((textTitle: string) => {
      title = textTitle;
    });

    this.translate.get('userRole_roleDelete', {value: 'userRole_roleDelete'}).subscribe((textMsg: string) => {
      message = textMsg;
    });

    const dialogData = new ConfirmDialogModel(title, message);


    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.roleService.deleteRole(this.role.id).pipe(
          first()
        ).subscribe(
          (): void => {
            window.location.reload();
          },
          (err: HttpErrorResponse): void => {
            console.log(err);
            const errMsg: string = err.error.error;
            console.log(errMsg);
          }
        );
      }
    });
  }

  editBtnClick() {

    this.router.navigate(['/userRole/update/' + this.role.id.toString()]).then(r => {
      console.log(r);
    });
  }
}
