import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Sensor} from '../../system/_model/Sensor';
import {SensorSetting} from '../_model/SensorSetting';
import {SensorSettingService} from '../_service/sensorSetting.service';
import {HttpErrorResponse} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';

export interface DialogData {
  sensor: Sensor;
  setting: SensorSetting;
}
@Component({
  selector: 'app-sensor-setting-create',
  templateUrl: './sensor-setting-create.component.html',
  styleUrls: ['./sensor-setting-create.component.css']
})
export class SensorSettingCreateComponent implements OnInit {
  editMode = false;

form: FormGroup;
  settingCategoryList: any[] = [
    {value: 'COMMUNICATION', viewValue: 'communication'},
    {value: 'MEASURE', viewValue: 'measure'},
  ];

  settingUnitTypeList: any[] = [
    {value: 'string', viewValue: 'string'},
    {value: 'number', viewValue: 'number'},
    {value: 'boolean', viewValue: 'bool'}
  ];

  currentUnitType = 'none';

  constructor(public formBuilder: FormBuilder, public  sensorSettingService: SensorSettingService,
              public dialogRef: MatDialogRef<SensorSettingCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData ) {

    this.form = this.formBuilder.group({
      settingName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      settingCategory: new FormControl('', [Validators.required, Validators.minLength(5)]),
      settingUnitType: new FormControl('', [Validators.required, Validators.minLength(5)]),
      numberValue: new FormControl('0'),
      stringValue: new FormControl(''),
      boolValue: new FormControl('false'),
    });
  }
  ngOnInit() {
    if (this.data.setting){
      this.editMode = true;
      this.form.patchValue(this.data.setting);
      this.currentUnitType = this.data.setting.settingUnitType;
    }
  }
  onDismiss() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    if (this.form.invalid) {
      console.log('form invalid');
      console.log(this.form);
      return;
    } else {
      if (!this.editMode) {
        this.sensorSettingService.create(this.data.sensor.id, this.form.value).pipe(
          first()
        ).subscribe(
          (data): void => {
            console.log('sensorSettingService created successful', true);
            this.data.sensor.sensorSettings = (this.data.sensor.sensorSettings) ? this.data.sensor.sensorSettings : [];
            this.data.sensor.sensorSettings.push(data.sensorSetting as SensorSetting);
            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse): void => {
            console.log(err);
          }
        );
      } else {
        this.sensorSettingService.update(this.data.setting.id, this.form.value)
          .pipe(first()).subscribe(
          data => {
            this.data.setting = data.sensorSetting;
            const index = this.data.sensor.sensorSettings.findIndex( setting => setting.id === this.data.setting.id );
            this.data.sensor.sensorSettings[index] = data.sensorSetting;
            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        );
      }
    }
  }


  currentUnitTypeChange($event: MatSelectChange) {
    this.currentUnitType = $event.value;
  }
}
