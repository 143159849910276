import {NgModule} from '@angular/core';
import {NgxBarChartComponent} from './ngx-bar-chart/ngx-bar-chart.component';
import {NgxPieChartComponent} from './ngx-pie-chart/ngx-pie-chart.component';
import {NgxLineChartComponent} from './ngx-line-chart/ngx-line-chart.component';
import {CommonModule} from '@angular/common';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {deChartTranslate, enChartTranslate, esChartTranslate, frChartTranslate} from './_translation/translate';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    NgxBarChartComponent,
    NgxLineChartComponent,
    NgxPieChartComponent,
  ],
  exports: [
    NgxBarChartComponent,
    NgxLineChartComponent,
    NgxPieChartComponent,
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatIconModule,
    RouterModule,
  ]
})
export class ChartingModule {

  constructor(translate: TranslateService) {
    translate.setTranslation('en', enChartTranslate, true);
    translate.setTranslation('fr', frChartTranslate, true);
    translate.setTranslation('de', deChartTranslate, true);
    translate.setTranslation('es', esChartTranslate, true);
  }
}
