import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {System} from '../_model/System';
import {DebugMessage} from '../_model/DebugMessage';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';

const MAX_LOG_COUNT = 256;

@Component({
  selector: 'app-system-debug-view',
  templateUrl: './system-debug-view.component.html',
  styleUrls: ['./system-debug-view.component.css']
})
export class SystemDebugViewComponent implements OnInit {
  @Input() system: System;

  public msgReceive: DebugMessage[] = [];

  constructor(public cd: ChangeDetectorRef, public eponimWebSocketService: EponimWebSocketService) {
  }

  ngOnInit() {
    // this.connectSocket();
  }

  public connectSocket() {
    this.msgReceive = [];
    this.eponimWebSocketService.newLogReceived.subscribe(() => {
      this.msgReceive = this.msgReceive.concat(this.eponimWebSocketService.msgReceive);
      if (this.msgReceive.length > MAX_LOG_COUNT) {
        this.msgReceive = this.msgReceive
          .slice(Math.max(this.msgReceive.length - MAX_LOG_COUNT, 1));
      }
      console.log(this.msgReceive);
      this.cd.markForCheck();
    });
  }

  public getColor(msg: DebugMessage) {
    if (msg) {
      if (msg.type === 'INF') {
        return 'yellow';
      } else if (msg.type === 'DBG') {
        return 'green';
      } else if (msg.type === 'WRN') {
        return 'orange';
      } else if (msg.type === 'CRT') {
        return 'red';
      } else if (msg.type === 'FTL') {
        return 'red';
      }
    }
    return 'white';
  }

  clear() {
    this.eponimWebSocketService.msgReceive = [];
  }
}
