
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './_guards';
import {SystemListComponent} from './component/system/system-list/system-list.component';
import {SystemPairingComponent} from './component/system/system-pairing/system-pairing.component';
import {SystemViewComponent} from './component/system/system-view/system-view.component';
import {MeasureViewComponent} from './component/measure/measure-view/measure-view.component';
import {MapComponent} from './component/shared/map/map.component';
import {MeasureCreateComponent} from './component/measure/measure-create/measure-create.component';
import {SystemDetailResolver} from './component/system/_resolver/system-detail-resolver';
import {StorageRuleResolver} from './component/storageRule/_resolver/storage-rule-resolver';
import {UserRoute} from './component/user/userModule';
import {UserRoleRoute} from './component/userRole/userRoleModule';
import {DashboardViewComponent} from './component/dashboard/dashboard-view/dashboard-view.component';
import {LegalNoticeComponent} from './component/legal-notice/legal-notice.component';

const routes: Routes = [
  {path: 'systemList', component: SystemListComponent, canActivate: [AuthGuard]},
  {path: 'systemAdd', component: SystemPairingComponent, canActivate: [AuthGuard]},
  {path: 'systemsView/:id', component: SystemViewComponent, canActivate: [AuthGuard],
    resolve: {
      system: SystemDetailResolver
    }
  },
  {path: 'viewSession/:systemID/:sessionID', component: MeasureViewComponent, canActivate: [AuthGuard]},
  {path: 'newMeasure/:id', component: MeasureCreateComponent, canActivate: [AuthGuard],
    resolve: {
      system: SystemDetailResolver,
      storageRules: StorageRuleResolver
    }
  },
  {path: '', children: UserRoute},
  {path: 'map', component: MapComponent, canActivate: [AuthGuard]},
  {path: 'user', children: UserRoute},
  {path: 'userRole', children: UserRoleRoute},
  {path: 'home', component: DashboardViewComponent, canActivate: [AuthGuard]},
  {path: 'legal', component: LegalNoticeComponent},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
