import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

export interface Info {
  name: string;
  value: string;
  icon: string;
  id?: string;
  style?: string;
  hint?: string;
}



@Component({
  selector: 'app-display-info',
  templateUrl: './display-info.component.html',
  styleUrls: ['./display-info.component.css']
})
export class DisplayInfoComponent implements OnInit {

  @Input() dataInfo: Info[] = [];
  @Input() margin = '0';

  constructor(public router: Router) {
  }

  ngOnInit() {
  }

  btnClick = (dest: string, ssid: string): void => {
    this.router.navigateByUrl('/' + dest + '/' + ssid);
  }
}
