import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {System} from '../_model/System';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';

@Component({
  selector: 'app-system-monitoring-view',
  templateUrl: './system-monitoring-view.component.html',
  styleUrls: ['./system-monitoring-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemMonitoringViewComponent implements OnInit {


  @Input() system: System;
  public isDisabled: boolean;
  public isAdmin: boolean;

  public diagStarted = false;

  constructor(public eponimWebSocketService: EponimWebSocketService) {
    this.isDisabled = true;
    const loginResponse = JSON.parse(localStorage.getItem('currentUser'));
    this.isAdmin = (loginResponse.user.role.roleName.toLowerCase() === 'admin');
  }

  ngOnInit(): void {
  }

  stop = async () => {
    this.diagStarted = false;
    await this.eponimWebSocketService.stopCurrentAction(this.system);
  };

  start = async () => {
    this.diagStarted = true;
    await this.eponimWebSocketService.launchDiagMode(this.system);
  };

}


