import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SensorMeasureSetting} from '../../_model/sensorMeasureSetting';
import {Sensor} from '../../../system/_model/Sensor';
import {SensorMeasureTypeEntity} from '../../../system/_model/SensorMeasureTypeEntity';

@Component({
  selector: 'app-measure-sensor-view',
  templateUrl: './measure-sensor-view.component.html',
  styleUrls: ['./measure-sensor-view.component.css'],
})
export class MeasureSensorViewComponent implements OnInit {
  @Input() sensor: Sensor;
  @Input() sensorMeasureSettingEntities: SensorMeasureSetting[] = [];
  @Input() newDataReceivedFromParent: EventEmitter<any> = new EventEmitter();
  public newDataReceived: EventEmitter<any> = new EventEmitter();
  public displayableSensorMeasureSettings: SensorMeasureSetting[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.newDataReceivedFromParent.subscribe(()=>{
      this.newDataReceived.emit(null);
    })
  }

  public getMeasureTypeList() {

    const measureTypeList: SensorMeasureTypeEntity[] = [];
    for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {

      if (measureTypeList.findIndex((tmp) => tmp.measureType === sensorMeasureSetting.sensorMeasureType.measureType) === -1) {

        if (sensorMeasureSetting.sensorMeasureType.measureType === 'VOLT' ||
            sensorMeasureSetting.sensorMeasureType.measureType === 'STATUS' ||
            sensorMeasureSetting.sensorMeasureType.measureType === 'TIME' ||
            sensorMeasureSetting.sensorMeasureType.measureType === 'GPSLOCATION'){
        }else{
          measureTypeList.push(sensorMeasureSetting.sensorMeasureType);
        }

      }
    }
    return measureTypeList;
  }

  public getSensorMeasureSettingByMeasureType(measureType: SensorMeasureTypeEntity) {
    const sensorMeasureSettingList: SensorMeasureSetting[] = [];
    for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {
      if (measureType.measureType === sensorMeasureSetting.sensorMeasureType.measureType) {
        sensorMeasureSettingList.push(sensorMeasureSetting);
      }
    }
    return sensorMeasureSettingList;
  }

  public checkboxChange = (eventFired): void => {

    const idx = this.displayableSensorMeasureSettings.findIndex((tmp) =>
      (tmp.sensorMeasureType.measureType === eventFired.sensorMeasureSetting.sensorMeasureType.measureType) &&
      (tmp.sensor.type === eventFired.sensorMeasureSetting.sensor.type) &&
      (tmp.storageRule.name === eventFired.sensorMeasureSetting.storageRule.name)
    );
    if (eventFired.param === 'unitChange') {
      if (idx > -1) {
        this.displayableSensorMeasureSettings[idx] = (eventFired.sensorMeasureSetting);
      }
    } else {
      if (idx === -1) {
        this.displayableSensorMeasureSettings.push(eventFired.sensorMeasureSetting);
      } else {
        this.displayableSensorMeasureSettings.splice(idx, 1);
      }
    }
    this.newDataReceived.emit(null);
  };
}
