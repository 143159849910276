import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SystemService} from '../../system/_service/system.service';
import {first} from 'rxjs/operators';
import {Rule} from '../_model/rule';
import {HttpErrorResponse} from '@angular/common/http';
import {RoleService} from '../_service/role.service';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.scss']
})
export class RoleCreateComponent implements OnInit {
  createForm: FormGroup;
  loading = false;
  submitted = false;
  rules: Rule[];

  constructor(public router: Router, public formBuilder: FormBuilder,
              public systemService: SystemService, public roleService: RoleService, public location: Location) {

  }

  get f(): { [key: string]: AbstractControl } {
    return this.createForm.controls;
  }

  async ngOnInit(): Promise<void> {

    this.createForm = this.formBuilder.group({

      roleName: new FormControl('', [Validators.required]),
      rules: [null, [Validators.required]],
    });

    await this.systemService.getAllRoute()
      .pipe(first()).subscribe(
        (data: any): void => {
          this.rules = data.data;
        },
        (): void => {
          this.rules = [];
        }
      );
  }


  onCancel() {
    this.location.back();
  }
  onSubmit = (): void => {
    this.submitted = true;

    if (this.createForm.invalid) {
      console.log('form invalid');
      console.log(this.createForm);
      return;
    } else {
      this.roleService.createRole(this.createForm.value).pipe(
        first()
      ).subscribe(
        (): void => {
          console.log('Registration successful', true);
          this.router.navigate(['/userRole/list']).then(r => {
            if (!r) {
              console.log('unable to access page login');
            }
          });
        },
        (err: HttpErrorResponse): void => {
          console.log(err);
          const errMsg: string = err.error.error;
          console.log(errMsg);
        }
      );
      this.loading = false;
    }
  }
}
