import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MeasureService} from '../_service/measure.service';
import {MeasureSessionService} from '../_service/measureSession.service';
import {MeasureSession, System} from '../../../_models';
import {SystemService} from '../../system/_service/system.service';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';

@Component({
  selector: 'app-measure-view',
  templateUrl: './measure-view.component.html',
  styleUrls: ['./measure-view.component.css'],
})

export class MeasureViewComponent implements OnInit {
  measureSession: MeasureSession = null;
  system: System;
  public dataLoaded: Promise<boolean>;
  public isAdmin = false;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */

  constructor(public router: Router,
              public activatedRoute: ActivatedRoute,
              public measureService: MeasureService,
              public measureSessionService: MeasureSessionService,
              public systemService: SystemService,
              public eponimWebSocketService: EponimWebSocketService,
              public cd: ChangeDetectorRef
  ) {

  }

  async ngOnInit(): Promise<void> {

    const loginResponse = JSON.parse(localStorage.getItem('currentUser'));
    this.isAdmin = (loginResponse.user.role.roleName.toLowerCase() === 'admin');
    await this.loadData();
  }

  public loadData = async () => {
    this.dataLoaded = Promise.resolve(false);
    const measureSessionID = Number(this.activatedRoute.snapshot.paramMap.get('sessionID'));
    const systemID = Number(this.activatedRoute.snapshot.paramMap.get('systemID'));
    await this.systemService.getSystem(systemID).pipe(first()).subscribe(async (data) => {
        this.system = data.systemDevice;

      },
      (err: HttpErrorResponse) => {
        console.log(err);
      });

    await this.measureSessionService.getSession(systemID, measureSessionID)
      .pipe(first()).subscribe(
        async (data: any) => {
          this.measureSession = data.measureSession;
          this.dataLoaded = Promise.resolve(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );

  };

  stop = async () => {
    await this.eponimWebSocketService.stopCurrentAction(this.system);
  };

  navigateToSystem = () => {
    this.router.navigateByUrl('/systemsView/' + this.activatedRoute.snapshot.paramMap.get('systemID'));
  };
}
