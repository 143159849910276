import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserEditComponent} from './user-edit/user-edit.component';
import {UserLoginComponent} from './user-login/user-login.component';
import {UserViewComponent} from './user-view/user-view.component';
import {UserListComponent} from './user-list/user-list.component';
import {UserCreateComponent} from './user-create/user-create.component';
import {UserService} from './_service/user.service';
import {User} from './_model/user';
import {DisplayInfoModule} from '../shared/display-info/displayInfoModule';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from '../../_guards';
import {FlexLayoutModule} from '@angular/flex-layout';
import { TranslateModule, TranslateService} from '@ngx-translate/core';
import {deUserTranslate, enUserTranslate, esUserTranslate, frUserTranslate} from './_translation/translate';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from "@angular/material/tooltip";

// Add this in app.routing-module to integrate users => {path: 'user', children: UserRoute},

export const UserRoute: any[] = [
  {path: '', component: UserLoginComponent},
  {path: 'login', component: UserLoginComponent},
  {path: 'list', component: UserListComponent, canActivate: [AuthGuard]},
  {path: 'update/:id', component: UserEditComponent, canActivate: [AuthGuard]},
  {path: 'view/:id', component: UserViewComponent, canActivate: [AuthGuard]},
  {path: 'register', component: UserCreateComponent},
];

@NgModule({
    imports: [
        CommonModule,
        DisplayInfoModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatCheckboxModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatSidenavModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule.forChild(),
        MatTooltipModule,
    ],
  providers: [UserService, User],
  declarations: [UserEditComponent, UserLoginComponent, UserViewComponent, UserListComponent, UserCreateComponent],
  exports: [UserEditComponent, UserLoginComponent, UserViewComponent, UserListComponent, UserCreateComponent],
})
export class UserModule {

  constructor(translate: TranslateService) {
    translate.setTranslation('en', enUserTranslate, true);
    translate.setTranslation('de', deUserTranslate, true);
    translate.setTranslation('fr', frUserTranslate, true);
    translate.setTranslation('es', esUserTranslate, true);
  }

}
