import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Name} from '../../../shared/chart/ngxChart';
import {System} from '../../../system/_model/System';
import {Measure} from '../../_model/measure';

interface DataToShow {
  title: string;
  value: string;
}

@Component({
  selector: 'app-measure-state-view',
  templateUrl: './measure-state-view.component.html',
  styleUrls: ['./measure-state-view.component.css'],
})
export class MeasureStateViewComponent implements OnInit, OnChanges {

  @Input() newmsg: Measure[];
  @Input() newmsgDebug: Measure[];
  @Input() system: System;
  lastData: Measure = new Measure(null, null, null);
  value: DataToShow[];
  result: Name[] = [];
  mounted = '';
  fileSystem = '';
  size = '';
  usage = '';
  dispo = '';
  view = [600, 200];

  constructor() {
  }

  ngOnInit(): void {
    this.value = [];
  }

  ngOnChanges(): void {
    if (this.newmsg[0] && this.newmsg[0].value !== this.lastData.value) {
      this.value = [];
      this.lastData = this.newmsg[0];

      // const tmpValue: string[] = this.lastData.value.split('\n')[1].split(' ');
      const tmpValue: string[] = [''];
      // const tmpTitle: string[] = this.lastData.value.split('\n')[0].split(' ');
      const tmpTitle: string[] = [''];
      const Value: string[] = [];
      const Title: string[] = [];
      const textToRemove: string[] = ['', ' '];

      for (const indexValue in tmpValue) {
        if (!textToRemove.includes(tmpValue[indexValue])) {
          Value.push(tmpValue[indexValue]);
        }
      }
      for (const indexTitle in tmpTitle) {
        if (!textToRemove.includes(tmpTitle[indexTitle])) {
          Title.push(tmpTitle[indexTitle]);
        }
      }
      if (Title[Title.length - 1].includes('on')) {
        Title[Title.length - 2] = Title[Title.length - 2] + ' ' + Title[Title.length - 1];
        Title.splice(-1, 1);
      }
      for (const index in Value) {
        if (Title[index] && Value[index]) {
          if (Title[index].toString() === 'Used' || Title[index].toString() === 'Avail') {
            this.result = this.result.concat([{
              name: Title[index].toString() + (' (Go)'),
              param: Number(Value[index].split('G')[0])
            }]);
          } else if (Title[index].toString() === 'Mounted on') {
            this.mounted = Value[index];
          } else if (Title[index].toString() === 'Filesystem') {
            this.fileSystem = Value[index];
          } else if (Title[index].toString() === 'Size') {
            this.size = Value[index];
          } else if (Title[index].toString() === 'Use%') {
            this.usage = Value[index];
            this.dispo = (100 - Number(this.usage.split('%')[0])).toString() + '%';
          } else {
            this.value.push({title: Title[index], value: Value[index]});
          }
        }
      }

      this.result.sort((a, b) => {
        return (a.name === 'Avail (Go)') ? -1 : 1;
      });
    }
  }
}
