import {NgModule} from '@angular/core';
import {DisplayInfoComponent} from './display-info.component';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    DisplayInfoComponent
  ],
  exports: [
    DisplayInfoComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule.forChild(),
    MatGridListModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatListModule,
    MatCheckboxModule,
    MatTooltipModule
  ]
})
export class DisplayInfoModule {
}
