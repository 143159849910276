import {StorageRule} from '../../storageRule/_model/StorageRule';
import {Sensor} from '../../system/_model/Sensor';
import {SensorMeasureTypeEntity} from '../../system/_model/SensorMeasureTypeEntity';
import {Measure} from './measure';


export class SensorMeasureSetting {
  public id: number;
  public storageRule: StorageRule;
  public sensor: Sensor;
  public sensorMeasureType: SensorMeasureTypeEntity;
  public sensorMeasures: Measure[];
}
