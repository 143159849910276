import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SensorService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(public http: HttpClient) {
  }

  public sensorList(systemId: string) {
    return this.http.get<any>(environment.apiUrl + '/sensor/list/' + systemId, this.httpOptions);
  }

  public sensorGet(sensorId: string) {
    return this.http.get<any>(environment.apiUrl + '/sensor/get/' + sensorId, this.httpOptions);
  }
}
