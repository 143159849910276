import {Component, Inject, OnInit} from '@angular/core';

import {SensorMeasureSetting} from '../../../measure/_model/sensorMeasureSetting';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface DataReceive {
    sensorMeasureSettingEntities: SensorMeasureSetting[];
}

@Component({
    selector: 'app-export-table-pop-up',
    templateUrl: './export-table-pop-up.component.html',
    styleUrls: ['./export-table-pop-up.component.css']
})
export class ExportTablePopUpComponent implements OnInit {

    formControle: FormGroup;
    sensorMeasureSettingEntities: SensorMeasureSetting[] = [];
    typeList = [];

    constructor(public dialogRef: MatDialogRef<ExportTablePopUpComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DataReceive,
                public formBuilder: FormBuilder) {
        this.sensorMeasureSettingEntities = data.sensorMeasureSettingEntities;
    }

    get f(): { [key: string]: AbstractControl } {
        return this.formControle.controls;
    }

    ngOnInit() {
        this.typeList = [
            {
                txt: 'CSV',
                mimeType: 'text/csv',
                prefix: 'csv'
            }, {
                txt: 'TXT',
                mimeType: 'text/plain',
                prefix: 'txt'
            }, {
                txt: 'JSON',
                mimeType: 'application/json',
                prefix: 'json'
            }
        ];
        this.formControle = this.formBuilder.group({
            type: new FormControl('', [Validators.required]),
        });
    }

    onConfirm(): void {
        const type = this.typeList.find((typeToFind) => typeToFind.txt === this.f.type.value);
        const txt: string = this.buildContent(type.txt);
        const blob = new Blob(['\ufeff', txt], {type: type.mimeType});
        const url = window.URL.createObjectURL(blob);
        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, 'export.' + type.prefix);
        } else {
            const a = document.createElement('a');
            a.href = url;
            a.download = 'export.' + type.prefix;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
        this.dialogRef.close(null);
    }

    buildContent(type: string): string {
        let content;
        switch (type) {
            case 'CSV':
                const separator = ';';
                content = 'Sensor Name' + separator + 'Measure Name' + separator + 'Storage Rule Name' + separator + 'Date (UTC)' + separator + 'Value' + separator + 'Unit' + '\n';
                for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {
                    for (const measure of sensorMeasureSetting.sensorMeasures) {
                        content += sensorMeasureSetting.sensor.type + separator + sensorMeasureSetting.sensorMeasureType.displayName + separator +sensorMeasureSetting.storageRule.name + separator + new Date(measure.date.toString()).toUTCString() + separator + measure.value + separator + sensorMeasureSetting.sensorMeasureType.unit + '\n';
                    }
                }
                break;
            case 'JSON':
                content = JSON.stringify(this.sensorMeasureSettingEntities);
                break;
            case 'TXT':
                content = JSON.stringify(this.sensorMeasureSettingEntities);
                break;
        }
        return content;
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(null);
    }
}
