import {ValueData} from '../../shared/chart/ngxChart';

export class Measure {
  id?: number;
  date: string;
  value?: number;
  valueData: ValueData;

  constructor(id, date, value) {
    this.id = id;
    this.date = date;
    this.value = value;
    this.valueData = {name: new Date(this.date), value: this.value};
  }
}
