import {ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {NgxChartComponent} from '../ngxChart';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-ngx-pie-chart',
  templateUrl: './ngx-pie-chart.component.html',
  styleUrls: ['./ngx-pie-chart.component.scss']
})
export class NgxPieChartComponent extends NgxChartComponent implements OnInit, OnChanges {

  containerName: string;
  container: HTMLElement;

  constructor( translate: TranslateService) {
    super(translate);
    this.data = [];
  }

  ngOnInit(): void {
    this.buildGraph(this.data);
  }

  buildGraph = (data: any): void => {
    this.result = [];
    for (const info of this.listName) {
      this.result.push({name: info.name, value: info.param});
    }
  }

  onResize = (event: Event): void => {
    this.container = (!this.container && this.containerName) ? document.getElementById(this.containerName) : this.container;
    if (this.container) {
      this.view = [this.container.offsetWidth - 75, this.container.offsetHeight];
    }
  }

  setcontainerName = (name: string): void => {
    this.containerName = name;
  }
}
