import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {User} from '../_model/user';
import {UserService} from '../_service/user.service';
import {RegistrationValidator} from '../validator/register.validator';
import {RoleService} from '../../userRole/_service/role.service';
import {Role} from '../../userRole/_model/role';
import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})

export class UserEditComponent implements OnInit {

  submitted = false;
  registerForm: FormGroup;
  user: User;
  isAdmin = false;
  roleList: Role[] = [];

  constructor(public userService: UserService, public roleService: RoleService, public formBuilder: FormBuilder,
              public router: Router,
              public activatedRoute: ActivatedRoute) {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  _createForm() {
    this.registerForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email]
      ],
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
    }, {
      validator: [
        RegistrationValidator.validate.bind(this),
        RegistrationValidator.strongPassword.bind(this)
      ]
    });
    if (this.isAdmin) {
      this.registerForm.addControl('roleName', new FormControl('', [Validators.required]));
    }
  }

  async ngOnInit(): Promise<void> {
    await this.userService.getUser(this.activatedRoute.snapshot.paramMap.get('id'))
      .pipe(first()).subscribe(
        (data: any): void => {
          this.user = data;
          this.isAdmin = this.user.role.roleName.toLowerCase() === 'admin';
          this._createForm();
          this.registerForm.patchValue(this.user);
        },
        (): void => {
          this.user = null;
        }
      );

    /*if (this.isAdmin) {
      this.roleService.getRoleList()
        .pipe(first()).subscribe(
        (data: any): void => {
          this.roleList = data;
          console.log(this.roleList);
        },
        (): void => {
          this.roleList = [];
        }
      );
    }*/
  }

  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  }

  onSubmit = (): void => {
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      console.log('form invalid');
      console.log(this.registerForm.errors);
      return;
    }
    this.user.email = this.registerForm.value.email;
    this.user.firstName = this.registerForm.value.firstName;
    this.user.lastName = this.registerForm.value.lastName;
    this.user.phone = this.registerForm.value.phone;
    this.user.password = this.registerForm.value.password;

    this.userService.updateUser(this.user.id.toString(), this.registerForm.value)
      .pipe(first()).subscribe(
      (): void => {
        console.log('User update successful', true);
        this.router.navigate(['/user/view/' + this.user.id]);
      },
      (err: HttpErrorResponse): void => {
        const errMsg: string = err.error.error;
        if (errMsg.indexOf('phone') >= 0) {
          this.registerForm.controls.phone.setErrors({incorrect: true});
        }
        if (errMsg.indexOf('email') >= 0) {
          this.registerForm.controls.email.setErrors({alreadyUsed: true});
        }
      }
    );
  }

}

