import {Component, Input, OnInit} from '@angular/core';
import {Sensor} from '../../system/_model/Sensor';
import {SensorSettingCreateComponent} from '../sensor-setting-create/sensor-setting-create.component';
import {SensorSetting} from '../_model/SensorSetting';
import {SensorService} from '../../system/_service/sensor.service';
import {first} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-sensor-setting-view',
  templateUrl: './sensor-setting-view.component.html',
  styleUrls: ['./sensor-setting-view.component.css']
})
export class SensorSettingViewComponent implements OnInit {

  @Input() sensor: Sensor;

  constructor(public dialog: MatDialog, public sensorService: SensorService) {
  }

  ngOnInit() {
    this.sensorService.sensorGet(this.sensor.id.toString()).pipe(first()).subscribe(
      (data) => {
        this.sensor = data.sensor;
      }
    );
  }

  showSettingForm(setting: SensorSetting = null) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {sensor: this.sensor, setting};
    this.dialog.open(SensorSettingCreateComponent, dialogConfig);
  }
}
