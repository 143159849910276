import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {System} from '../../../_models';
import {Router} from '@angular/router';
import {SystemService} from '../_service/system.service';

@Component({
  selector: 'app-system-pairing',
  templateUrl: './system-pairing.component.html',
  styleUrls: ['./system-pairing.component.css']
})
export class SystemPairingComponent implements OnInit {
  pairingForm: FormGroup;
  error = '';
  public httpErrorService: any;

  constructor(public http: HttpClient, public formBuilder: FormBuilder, public systemService: SystemService, public router: Router) {
  }

  ngOnInit() {
    console.log('ngOnInit ');
    this.pairingForm = this.formBuilder.group({
      MAC_ADDR: new FormControl('', [Validators.required])
    });

    console.log('mac ' + this.f.MAC_ADDR.value);
  }

  get f() {
    return this.pairingForm.controls;
  }

  btnClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  }

  onPairingSystem = () => {
    if (this.pairingForm.invalid) {
      console.log('form invalid');
      console.log(this.pairingForm.errors);
      return;
    }
    console.log('systemPairing 1 ');
    const system = new System();
    system.MAC_ADDR = this.f.MAC_ADDR.value;

    this.systemService.systemPairing(system)
      .pipe(first()).subscribe(
      (data: any) => {
        console.log('Pairing system successful', true);
        this.router.navigateByUrl('/systemList');
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }
}
