import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {MeasureSessionService} from '../_service/measureSession.service';
import {Router} from '@angular/router';
import {System} from '../../system/_model/System';
import {MeasureSession} from '../_model/measureSession';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';
import {ConfirmDialogModel} from '../../shared/Pop-up/_model/ConfirmDialogModel';
import {ConfirmDialogComponent} from '../../shared/Pop-up/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.css']
})
export class MeasureListComponent implements OnInit {

  @Input() system: System;
  displayedColumns = ['location', 'startDate', 'stopDate', 'status', 'actions'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<MeasureSession>;


  constructor(public router: Router,
              public measureSessionService: MeasureSessionService,
              public eponimWebSocketService: EponimWebSocketService,
              public dialog: MatDialog,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  getIconName(state: string) {
    if (state === 'created') {
      return 'schedule';
    } else if (state === 'aborted') {
      return 'cancel_schedule_send';
    } else if (state === 'measuring') {
      return 'move_to_inbox';
    } else if (state === 'finished') {
      return 'done';
    }

    return 'device_unknown';
  }

  getMeasure(state: string) {
    if (state === 'measuring') {
      return 1;
    } else  {
      return 0;
    }
  }


  loadData(): void {
    this.measureSessionService.measureSessionList(this.system.id)
      .pipe(first()).subscribe(
      (data) => {
        this.system.measureSessions = data.measureSessions;
        this.dataSource = new MatTableDataSource(data.measureSessions);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }

  measeureActionClick = (dest: string): void => {
    this.router.navigateByUrl('/' + dest);
  };

  delete = (measureSession: MeasureSession): void => {
    let message = '';
    let title = '';

    this.translate.get('stopMeasure').subscribe((textMsg: string) => {
      message = textMsg;
    });

    this.translate.get('confirmStop').subscribe((textTitle: string) => {
      title = textTitle;
    });

    const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.measureSessionService.measureSessionDelete(Number(measureSession.id)).pipe(first()).subscribe(
          async (data: any) => {
            await this.eponimWebSocketService.removeMeasureSession(measureSession, this.system);
            this.loadData();
          }, (err: any) => {
            console.log(err);
          }
        );
      }
    });
  };
}
