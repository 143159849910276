import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {SensorMeasureSetting} from '../../_model/sensorMeasureSetting';
import {MatTableDataSource} from '@angular/material/table';
import {Measure} from '../../_model/measure';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

export class DataToDisplay {
  type: string;
  unit: string;
  date: string;
  measure: Measure;
  value: number;


  constructor(measure: Measure, sensorMeasureSetting: SensorMeasureSetting) {
    this.measure = measure;
    this.unit = sensorMeasureSetting.sensorMeasureType.unit;
    this.type = sensorMeasureSetting.sensorMeasureType.displayName + ' - ' + sensorMeasureSetting.storageRule.name;
    this.date = measure.date;
    this.value = measure.value;

  }
}

@Component({
  selector: 'app-measure-sensor-data-table-viewer',
  templateUrl: './measure-sensor-data-table-viewer.component.html',
  styleUrls: ['./measure-sensor-data-table-viewer.component.css'],
})

export class MeasureSensorDataTableViewerComponent implements OnInit {
  @Input() sensorMeasureSettingEntities: SensorMeasureSetting[];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @Input() newDataReceived: EventEmitter<any> = new EventEmitter();

  displayedColumns = ['date', 'type', 'value', 'unit'];
  dataSource: MatTableDataSource<DataToDisplay> = new MatTableDataSource<DataToDisplay>();

  constructor() {
  }



  ngOnInit() {
    this.dataSource = new MatTableDataSource<DataToDisplay>();

    this.newDataReceived.subscribe(()=>{
      this.refreshTable();
   //   this.dataSource.paginator = this.paginator;
   //   this.dataSource.sort = this.sort;
    })

    this.dataSource.sortingDataAccessor = (dataSource, property): string | number => {
      switch (property) {
        case 'date': return dataSource.measure.date.toString();
        default: return dataSource[property];
      }
    };

  }



  public refreshTable() {
    const array: DataToDisplay[] = [];
    this.dataSource.data = array;

    for (const sensorMeasureSetting of this.sensorMeasureSettingEntities) {
      if (sensorMeasureSetting.sensorMeasures) {

        console.log(sensorMeasureSetting.sensorMeasures);
        for (const measure of sensorMeasureSetting.sensorMeasures) {
          const data: DataToDisplay = new DataToDisplay(measure, sensorMeasureSetting);
          array.push(data);
        }

        this.dataSource.data = array;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }
}
