import {FormGroup} from '@angular/forms';

export class RegistrationValidator {
  static validate(registrationFormGroup: FormGroup): object | boolean {
    if (registrationFormGroup.controls.password) {
      const password: string = registrationFormGroup.controls.password.value;
      const repeatPassword: string = registrationFormGroup.controls.confirmPassword.value;

      if (repeatPassword.length <= 0) {
        return null;
      }

      if (repeatPassword !== password) {
        registrationFormGroup.controls.confirmPassword.setErrors({doesMatchPassword: true});
        return {
          doesMatchPassword: true
        };
      }
      return null;
    } else {
      return true;
    }
  }

  public static strongPassword(registrationFormGroup: FormGroup): object {
    if (registrationFormGroup.controls.password) {
      const hasNumber: boolean = /\d/.test(registrationFormGroup.controls.password.value);
      const hasUpper: boolean = /[A-Z]/.test(registrationFormGroup.controls.password.value);
      const hasLower: boolean = /[a-z]/.test(registrationFormGroup.controls.password.value);
      const lengthOk: boolean = registrationFormGroup.controls.password.value.toString().length >= 8;
      const hasSpecialCharacter: boolean = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(registrationFormGroup.controls.password.value);

      const valid: boolean = hasNumber && hasUpper && hasLower && lengthOk;
      if (!valid) {
        if (!hasNumber) {
          registrationFormGroup.controls.password.setErrors({noNumber: true});
        }
        if (!hasUpper) {
          registrationFormGroup.controls.password.setErrors({noUpper: true});
        }
        if (!hasLower) {
          registrationFormGroup.controls.password.setErrors({noLower: true});
        }
        if (!lengthOk) {
          registrationFormGroup.controls.password.setErrors({noLonger: true});
        }
        if (hasSpecialCharacter) {
          registrationFormGroup.controls.password.setErrors({SpecialCharact : true});
        }

        return {strong: true};
      }
      return null;
    } else {
      return null;
    }
  }
}


