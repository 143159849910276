import {Component, Input, OnInit} from '@angular/core';
import {Sensor} from '../_model/Sensor';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.css']
})
export class SensorListComponent implements OnInit {
  @Input() sensors: Sensor[];
  displayedColumns = ['name', 'model', 'sn', 'pn'];
  constructor() { }

  ngOnInit() {
  }

}
