import {SensorSetting} from '../_model/SensorSetting';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SensorSettingService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(public http: HttpClient) {
  }

  public  create(sensorId: number, sensorSetting: SensorSetting) {
    return this.http.post<any>(environment.apiUrl + '/sensorsetting/create/' + sensorId,
      JSON.stringify(sensorSetting),  this.httpOptions);
  }
  public  update(id: number, sensorSetting: SensorSetting) {
    return this.http.put<any>(environment.apiUrl + '/sensorsetting/update/' + id,
      JSON.stringify(sensorSetting),  this.httpOptions);
  }
}
