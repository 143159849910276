import {AfterViewInit, Component, Input, OnChanges} from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import * as projection from 'ol/proj';
import {GPSLocation} from '../../../_models/GPSLocation';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges, AfterViewInit {
  @Input() mapName: string = null;
  @Input() markerToDisplay: any[] = null;

  map: any = null;

  constructor() {}

  static generateRandomName(): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  ngAfterViewInit(): void {
    console.log("MapComponent after init ");
    this.mapName = (this.mapName) ? this.mapName : MapComponent.generateRandomName();
    if (document.getElementById(this.mapName)) {
      this.initializeMap();
    }
    if (this.markerToDisplay && this.map) {
      this.createMarker(this.markerToDisplay);
    }
  }

  ngOnChanges(): void {
    if (document.getElementById(this.mapName)) {
      this.initializeMap();
    }
    if (this.map) {
      if (this.markerToDisplay) {
        this.createMarker(this.markerToDisplay);
      }
    }
  }

  initializeMap(): void {
    if (!this.map) {
      this.map = new Map({
        target: this.mapName,
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: projection.fromLonLat([-4.439909, 48.429275]),
          zoom: 8
        })
      });
    }
  }

  createMarker(localisations: GPSLocation[]): void {
    if (this.map) {
      this.map.getLayers().forEach((layer: any) => {
        if (layer && layer.declutter_ !== undefined) {
          this.map.removeLayer(layer);
        }
      });
      this.map.addLayer(new TileLayer({
          source: new OSM()
        })
      );
      const features: any[] = [];
      for (const loc of localisations) {
        const marker = new Feature({
          geometry: new Point(
            projection.fromLonLat([loc.long, loc.lat])
          ),
        });
        marker.setStyle(new Style({
          image: new Icon(({
            crossOrigin: 'anonymous',
            src: './assets/img/map-marker-icon.png',
      }))
        }));
        features.push(marker);
      }

      const vectorSource = new VectorSource({
        features
      });
      const markerVectorLayer = new VectorLayer({
        source: vectorSource,
      });

      this.map.addLayer(markerVectorLayer);
    }
  }
}
