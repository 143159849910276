import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SensorMeasureSettingService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: null,
  };

  constructor(public http: HttpClient) {
  }

  public sensorMeasureSettingGet(
    measureId: string,
    sensorId?: string,
    sensorMesureTypeId?: string,
    storageRuleId?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    this.httpOptions.params = null;

    let params = new HttpParams();

    // Begin assigning parameters
    params = (!measureId) ? params : params.append('measureId', measureId);
    params = (!sensorId) ? params : params.append('sensorId', sensorId);
    params = (!sensorMesureTypeId) ? params : params.append('sensorMesureTypeId', sensorMesureTypeId);
    params = (!storageRuleId) ? params : params.append('storageRuleId', storageRuleId);
    params = (!startDate) ? params : params.append('startDate', startDate.toDateString());
    params = (!endDate) ? params : params.append('endDate', endDate.toDateString());
    this.httpOptions.params = params;

    const url = environment.apiUrl + '/sensorMeasureSetting/get/' + measureId;

    return this.http.get<any>(url, this.httpOptions);
  }

  public sensorMeasureSettingGetMeasure = (sensorMeasureSettingId: string, startDate?: Date, endDate?: Date) => {
    this.httpOptions.params = null;
    let params = new HttpParams();
    params = (!startDate) ? params : params.append('startDate', startDate.toUTCString());
    params = (!endDate) ? params : params.append('endDate', endDate.toUTCString());
    this.httpOptions.params = params;

    const url = environment.apiUrl + '/sensorMeasureSetting/getMeasure/' + sensorMeasureSettingId;
    console.log(url);
    return this.http.get<any>(url, this.httpOptions);
  }
  public getMeasureBySensorMeasureSetting = (sensorMeasureSettingId: number, startDate?: Date, endDate?: Date) => {
    this.httpOptions.params = null;
    let params = new HttpParams();
    params = (!startDate) ? params : params.append('startDate', startDate.toUTCString());
    params = (!endDate) ? params : params.append('endDate', endDate.toUTCString());
    this.httpOptions.params = params;

    const url = environment.apiUrl + '/sensorMeasureSetting/getMeasure/' + sensorMeasureSettingId;
   // console.log(url);
    return this.http.get<any>(url, this.httpOptions);
  }
}
