import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, Input, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {Rule} from '../_model/rule';

class RuleNode {
  name: string;
  children?: Rule [];

  constructor() {
    this.children = [];
  }
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-rule-list',
  templateUrl: './rule-list.component.html',
  styleUrls: ['./rule-list.component.css']
})
export class RuleListComponent implements OnInit {
  @Input() rules: Rule[] = [];

  public _transformer = (node: any, level: number): ExampleFlatNode => {
    if (level === 0) {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.name,
        level,
      };
    } else {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.resource,
        level,
      };
    }
  }

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children
  );

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit(): void {
    const rulesMap: Map<string, Rule[]> = new Map<string, Rule[]>();
    for (const rule of this.rules) {
      const splitted = rule.resource.split('/');
      if (splitted.length > 1) {
        if (!rulesMap.has(splitted[1])) {
          rulesMap.set(splitted[1], [rule]);
        } else {
          rulesMap.get(splitted[1]).push(rule);
        }
      }
    }

    const treeData: RuleNode[] = [];

    for (const key of rulesMap.keys()) {
      const ruleNode = new RuleNode();
      ruleNode.name = key;
      ruleNode.children = rulesMap.get(key);
      treeData.push(ruleNode);
    }
    this.dataSource.data = treeData;
  }
}
