import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {MeasureSessionService} from '../../measure/_service/measureSession.service';
import {SystemService} from '../_service/system.service';
import {System} from '../../../_models';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-system-view',
  templateUrl: './system-view.component.html',
  styleUrls: ['./system-view.component.css'],
})
export class SystemViewComponent implements OnInit {

  system: System;
  isAdmin = false;
  dataLoaded: Promise<boolean>;

  constructor(public router: Router, public activatedRoute: ActivatedRoute, public dialog: MatDialog,
              public measureSessionService: MeasureSessionService, public systemService: SystemService) {
  }

  async ngOnInit() {
    this.dataLoaded = Promise.resolve(false);
    const loginResponse = JSON.parse(localStorage.getItem('currentUser'));
    this.isAdmin = (loginResponse.user.role.roleName.toLowerCase() === 'admin');
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    await this.systemService.getSystem(id)
      .pipe(first()).subscribe(
        (data) => {
       //   console.log(JSON.stringify(data.systemDevice));
          console.log('get device');
          this.system = data.systemDevice;
          this.dataLoaded = Promise.resolve(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  btnClick = (dest: string) => {
    this.router.navigateByUrl('/' + dest + '/' + this.activatedRoute.snapshot.paramMap.get('id'));
  }

  public navigateToSystemList = () => {
    this.router.navigateByUrl('/systemList');
  }
}
