import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {hashCode} from '../../../utils/commonTools';
import {MeasureSession} from '../_model/measureSession';
import {System} from '../../system/_model/System';
import {SensorMeasureSetting} from '../_model/sensorMeasureSetting';
import {ExportService, Format} from '../_service/export.service';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatSelectChange} from '@angular/material/select';
import {FormControl, FormGroup, Validators, FormBuilder, AbstractControl} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-measure-action-view',
  templateUrl: './measure-action-view.component.html',
  styleUrls: ['./measure-action-view.component.css'],
})
export class MeasureActionViewComponent implements OnInit {
  loading = false;
  exportForm: FormGroup;
  dateForm: FormGroup;
  selectedSensorMeasureSetting: SensorMeasureSetting[] = [];
  calendarShow = false;

    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
    @Input() measureSession: MeasureSession;
    @Input() system: System;

    formats: Format[] = [
      {
        viewValue: 'CSV',
        mimeType: 'text/csv',
        value: 'csv'
      }, {
        viewValue: 'TXT',
        mimeType: 'text/plain',
        value: 'txt'
      }, {
        viewValue: 'JSON',
        mimeType: 'application/json',
        value: 'json'
      }
    ];
    startDateSelected: Date;
    endDateSelected: Date;
    formatChoose: Format = null;

  submitted = false;

  constructor(public exportService: ExportService, public formBuilder: FormBuilder,public datepipe: DatePipe) {
    this.exportForm = this.formBuilder.group({
      format: new FormControl('', [Validators.required]),
      sensor: new FormControl('', [Validators.required]),
    });


  }


  ngOnInit(): void {
    this.dateForm = this.formBuilder.group({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });
  }

  get f(): {[key: string]: AbstractControl} {
    return this.exportForm.controls;
  }

  get fdate(): {[key: string]: AbstractControl} {
    return this.dateForm.controls;
  }

  public downloadZip(content): void {
    this.loading = false;

    console.log(content);
    const blob = new Blob([content], {type: 'application/zip'});
    const url = window.URL.createObjectURL(blob);
    const startDate = (this.calendarShow) ? this.startDateSelected : this.measureSession.startDate;
    const endDate = (this.calendarShow) ? this.endDateSelected : this.measureSession.endDate;
    const fileName =  this.measureSession.location+'_'+ this.datepipe.transform(new Date(startDate), 'yyyyMMdd')+'_'+
      this.datepipe.transform(new Date(endDate), 'yyyyMMdd') +'.zip';
    if (navigator.msSaveOrOpenBlob) {
      console.log(' navigator.msSaveOrOpenBlob');
      navigator.msSaveBlob(blob, fileName);
    } else {

      console.log(' navigator.msSaveOrOpenBlob else ');
      const downloadPipe = document.createElement('a');
      downloadPipe.href = url;
      downloadPipe.download = fileName;
      downloadPipe.type = 'application/zip';
      document.body.appendChild(downloadPipe);
      downloadPipe.click();
     // document.body.removeChild(downloadPipe);
    }
    window.URL.revokeObjectURL(url);
  }

  public selected($event: MatSelectChange) {
    this.selectedSensorMeasureSetting = $event.value;
  }

  public chooseFormat($event: MatSelectChange) {
    this.formatChoose = $event.value;
  }

  public getColorFrom(str: string) {
    return hashCode(str);
  }

  public showCalendar() {
    this.calendarShow = !this.calendarShow;
  }



  public async prepareDownload() {

    console.log('prepareDownload ');
    this.submitted = true;

    if (this.exportForm.invalid) {
      return;
    }
/*
    if (this.calendarShow) {
      if (this.dateForm.invalid) {
        return;
      }
    }*/
    const startDate = (this.calendarShow) ? this.startDateSelected : this.measureSession.startDate;
    const endDate = (this.calendarShow) ? this.endDateSelected : this.measureSession.endDate;

    const objectList: any[] = [];

    console.log(JSON.stringify(startDate))
    if (startDate && endDate) {

      for (const sensorMeasuresetting of this.selectedSensorMeasureSetting) {
        const object = {
          id: sensorMeasuresetting.id,
          unit: sensorMeasuresetting.sensorMeasureType.unit,
        };
        objectList.push(object);
      }

      this.exportService.getZip(this.measureSession.id, objectList, startDate, endDate, this.formatChoose).subscribe((response) => {
        window.open('https://www.sonel.org/eponim/export.zip');
      }, (err) => {
        console.log('err');
        console.log(err);
      });
    }

  }
}
