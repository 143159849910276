import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {MeasureSessionService} from '../_service/measureSession.service';
import {MeasureSession, System} from '../../../_models';

import {StorageRuleCreateComponent} from '../../storageRule/storage-rule-create/storage-rule-create.component';
import {StorageRule} from '../../storageRule/_model/StorageRule';
import {SensorMeasureTypeEntity} from '../../system/_model/SensorMeasureTypeEntity';
import {Sensor} from '../../system/_model/Sensor';
import {SensorMeasureSetting} from '../_model/sensorMeasureSetting';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {EponimWebSocketService} from '../../../_services/EponimWebSocket.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

class MeasureTypeForm {
  measureType: SensorMeasureTypeEntity;
  storageRuleControl: FormControl;
  form: FormGroup;
}

@Component({
  selector: 'app-measure-create',
  templateUrl: './measure-create.component.html',
  styleUrls: ['./measure-create.component.css'],
})

export class MeasureCreateComponent implements OnInit {
  measureForm: FormGroup;
  settingForm: FormGroup;
  submitted = false;
  success = false;
  public isAdmin: boolean;
  error = '';

  public dataLoaded: Promise<boolean>;
  public system: System;
  public storageRuleEntities: StorageRule[] = [];
  public visibilityMap: Map<string, boolean> = new Map();
  public sensorMapFormArray: Map<Sensor, MeasureTypeForm[]> = new Map();

  constructor(public router: Router, public activatedRoute: ActivatedRoute, public formBuilder: FormBuilder,
              public dialog: MatDialog, public measureSessionService: MeasureSessionService,
              public eponimWebSocketService: EponimWebSocketService, public location: Location) {
    this.dataLoaded = Promise.resolve(false);
  }

  get f() {
    return this.measureForm.controls;
  }

  async ngOnInit() {
    this.submitted = false;
    this.success = false;

    const loginResponse = JSON.parse(localStorage.getItem('currentUser'));
    this.isAdmin = (loginResponse.user.role.roleName.toLowerCase() === 'admin');

    this.activatedRoute.data.subscribe(data => {
      this.system = data.system.systemDevice;
      this.storageRuleEntities = data.storageRules.storageRuleEntities;
      this.system.sensors.forEach((sensor) => {
        const formArray: MeasureTypeForm[] = [];

        this.sensorMapFormArray.set(sensor, formArray);
        console.log(sensor);
        if (sensor.sensorMeasuresType) {
          sensor.sensorMeasuresType.forEach((measureType) => {
            if (measureType.measureType === 'VOLT' ||
              measureType.measureType === 'STATUS' ||
              measureType.measureType === 'TIME' ||
              measureType.measureType === 'GPSLOCATION'){
            }else {
              this.visibilityMap.set(sensor.id + measureType.measureType, false);
              const measureTypeForm = new MeasureTypeForm();
              const currentForm = new FormGroup({});
              measureTypeForm.storageRuleControl = new FormControl([], [Validators.required]);
              currentForm.addControl(measureType.measureType, new FormControl(false, [Validators.required]));
              currentForm.addControl(measureType.measureType + 'Storage', measureTypeForm.storageRuleControl);
              measureTypeForm.form = currentForm;
              measureTypeForm.measureType = measureType;
              formArray.push(measureTypeForm);
            }
          });
        }
      });

      this.measureForm = this.formBuilder.group({
        location: new FormControl('', [Validators.required]),
        startDate: new FormControl(null, [Validators.required]),
        endDate: new FormControl(null, [Validators.required]),
        comment: new FormControl()
      }, {
        validator: []
      });

      this.settingForm = this.formBuilder.group({
        webSocketConsumer: new FormControl('1', [Validators.required]),
        apiConsumer: new FormControl('1', [Validators.required]),
        fileConsumer: new FormControl('1', [Validators.required])
      }, {
        validator: []
      });
      this.dataLoaded = Promise.resolve(true);
    });
  }

  createStorageRule() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.height = '550px';
    this.dialog.open(StorageRuleCreateComponent, dialogConfig);
  }

  allowToShowStoraleRule(sensor: Sensor, measureType: SensorMeasureTypeEntity) {
    return !this.visibilityMap.get(sensor.id + measureType.measureType);
  }

  onSlideChange(sensor: Sensor, measureType: SensorMeasureTypeEntity) {
    this.visibilityMap.set(sensor.id + measureType.measureType, !this.visibilityMap.get(sensor.id + measureType.measureType));
  }

  onCancel() {
    this.location.back();
  }

  async onSubmit() {
    console.log('click');
    this.submitted = true;

    if (this.measureForm.invalid) {
      this.error ='Form invalid';
      // return;
    }
    let measureSession: MeasureSession;
    measureSession = this.measureForm.value as MeasureSession;
    console.log(measureSession);
    measureSession.sensorMeasureSettingEntities = [];
    for (const sensor of this.sensorMapFormArray.keys()) {
      for (const measureTypeForm of this.sensorMapFormArray.get(sensor)) {
    //    if (!measureTypeForm.form.invalid) {
          if (measureTypeForm.form.get(measureTypeForm.measureType.measureType).value === true) {
            for (const storageRule of measureTypeForm.storageRuleControl.value) {
              const sensorMeasureSetting = new SensorMeasureSetting();
              sensorMeasureSetting.sensor = sensor;
              sensorMeasureSetting.sensorMeasureType = measureTypeForm.measureType;
              sensorMeasureSetting.storageRule = storageRule;
              measureSession.sensorMeasureSettingEntities.push(sensorMeasureSetting);
            }
      //    }
        }
      }
    }

    this.measureSessionService.measureSessionCreate(this.system.id, measureSession).pipe(
      first()
    ).subscribe(
      async (data: any): Promise<void> => {
        console.log(data);
        console.log('create  successful', true);
        this.success = true;
        // await this.eponimWebSocketService.addNewMeasureSession(measureSession, this.system);
        await this.router.navigateByUrl('systemsView/' + this.system.id);
      },
      (err: HttpErrorResponse): void => {
        this.success = false;
        console.log(err);
        if (err.error) {
          this.error = err.error.error.msg;
        }

      }
    );
    if (this.settingForm.invalid) {
      this.success = false;
      console.log('settingForm invalid');
      console.log(this.settingForm);
      // return;
    } else {
      console.log(this.settingForm.value);
    }
  }

}
