import {Component, OnInit} from '@angular/core';
import {SystemService} from '../../system/_service/system.service';
import {first} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {System} from '../../system/_model/System';

@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.css']
})
export class DashboardViewComponent implements OnInit {
  public systemList: System[];
  public dataLoaded: Promise<boolean>;

  constructor(public systemService: SystemService) {
  }


  ngOnInit(): void {
    this.dataLoaded = Promise.resolve(false);
    this.systemService.systemList()
      .pipe(first()).subscribe(
      data => {
        this.systemList = data.systemDevices;
        this.dataLoaded = Promise.resolve(true);
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    );
  }

}
