import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {SensorMeasureSetting} from '../../measure/_model/sensorMeasureSetting';
import {SensorMeasureTypeEntity} from '../../system/_model/SensorMeasureTypeEntity';
// @ts-ignore
import * as convert from 'convert-units';

@Component({
    selector: 'app-display-storage-rules',
    templateUrl: './display-storage-rules.component.html',
    styleUrls: ['./display-storage-rules.component.css']
})
export class DisplayStorageRulesComponent implements OnChanges {

    @Input() sensorType: string = null;
    @Input() measureType: SensorMeasureTypeEntity = null;
    @Input() sensorMeasureSettingEntities: SensorMeasureSetting[] = [];

    @Output() eventCheckbox = new EventEmitter<any>();

    convertPossibilities: string[] = [];
    unitConvert: string = null;

    constructor() {
    }

    ngOnChanges(): void {
        const converter = convert(1);
        this.convertPossibilities = converter.possibilities(this.sensorMeasureSettingEntities[0].sensorMeasureType.unitType.toLowerCase() as any);
        this.unitConvert = this.sensorMeasureSettingEntities[0].sensorMeasureType.unit;
    }

    checkboxChange = (event: boolean, sensorMeasureSetting: SensorMeasureSetting, param?: string): void => {
        const object = {
            event,
            sensorMeasureSetting,
            param: (param) ? param : null
        };
        this.eventCheckbox.emit(object);
    }

    unitTypechange = ($event) => {
        for (const indexSensorMeasureSetting in this.sensorMeasureSettingEntities) {
            if (this.sensorMeasureSettingEntities.hasOwnProperty(indexSensorMeasureSetting)) {
                if (this.sensorMeasureSettingEntities[indexSensorMeasureSetting].sensorMeasures) {
                    for (const measure of this.sensorMeasureSettingEntities[indexSensorMeasureSetting].sensorMeasures) {
                        measure.value = convert(measure.value).from(this.sensorMeasureSettingEntities[indexSensorMeasureSetting].sensorMeasureType.unit as any).to($event.value);
                    }
                }
                this.sensorMeasureSettingEntities[indexSensorMeasureSetting].sensorMeasureType.unit = $event.value;
                this.checkboxChange(false, this.sensorMeasureSettingEntities[indexSensorMeasureSetting], 'unitChange');
            }
        }
        this.sensorMeasureSettingEntities = this.sensorMeasureSettingEntities.concat([]);
        this.unitConvert = $event.value;
    }
}
