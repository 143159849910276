export const frRoleTranslate = {
  userRole_name: 'Nom',
  userRole_validate: 'Valider',
  userRole_cancel: 'Annuler',
  userRole_update: 'Editer',
  userRole_newRole: 'Nouveau rôle',
  userRole_rules: 'Règles',
  userRole_rightsManagement: 'Gestion des droits',
  userRole_confirmDelete: 'Confirmer la suppression',
  userRole_roleDelete: 'Etes-vous certain de vouloir supprimer ce rôle ?',
};

export const enRoleTranslate = {
  userRole_name: 'Name',
  userRole_validate: 'Validate',
  userRole_cancel: 'Cancel',
  userRole_update: 'Edit',
  userRole_newRole: 'New role',
  userRole_rules: 'Rules',
  userRole_rightsManagement: 'Rights management',
  userRole_confirmDelete: 'Confirm deletion',
  userRole_roleDelete: 'Are you sure you want to delete this role?',
};

export const deRoleTranslate = {
  userRole_name: 'Name',
  userRole_validate: 'Validate',
  userRole_cancel: 'Stornieren',
  userRole_update: 'Bearbeiten',
  userRole_newRole: 'Neue Rolle',
  userRole_rules: 'Regeln',
  userRole_rightsManagement: 'Rechteverwaltung',
  userRole_confirmDelete: 'Bestätigen Sie den Löschvorgang',
  userRole_roleDelete: 'Möchten Sie diese Rolle wirklich löschen?',
};

export const esRoleTranslate = {
  userRole_name: 'Apellido',
  userRole_validate: 'Validar',
  userRole_cancel: 'Anular',
  userRole_update: 'Editar',
  userRole_newRole: 'Nuevo rol',
  userRole_rules: 'Normas',
  userRole_rightsManagement: 'Gestión de derechos',
  userRole_confirmDelete: 'Confirmar eliminación',
  userRole_roleDelete: '¿Estás seguro de que deseas eliminar esta función?',
};
