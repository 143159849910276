import {ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {NgxChartComponent, ValueData} from '../ngxChart';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-ngx-bar-chart',
  templateUrl: './ngx-bar-chart.component.html',
  styleUrls: ['./ngx-bar-chart.component.scss']
})
export class NgxBarChartComponent extends NgxChartComponent implements OnInit, OnChanges {

  constructor(translate: TranslateService) {
    super( translate);
  }

  ngOnInit(): void {
    this.buildGraph(this.data);
  }

  buildGraph = (data: any): void => {
    let dataDataHoursOn: any[] = [];

    for (const dataStat of data) {
      const dataHoursOn: ValueData = {name: undefined, value: undefined};
      dataHoursOn.name = new Date(dataStat.date);
      dataHoursOn.value = dataStat.hoursOn + dataStat.minutesOn / 100;
      dataDataHoursOn.push(dataHoursOn);
    }

    dataDataHoursOn = this.sortbyDate(dataDataHoursOn);

    if (dataDataHoursOn.length > 0) {
      this.xAxisTicks = [];
      this.xAxisTicks.push(dataDataHoursOn[0].name);
      this.xAxisTicks.push(dataDataHoursOn[dataDataHoursOn.length - 1].name);
    }

    this.result = [];

    this.result = dataDataHoursOn;
  }
}
