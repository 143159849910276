import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NavigationComponent} from './navigation.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {
  deNavigationTranslate,
  enNavigationTranslate,
  esNavigationTranslate,
  frNavigationTranslate
} from './_translation/translate';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    NavigationComponent
  ],
  exports: [
    NavigationComponent
  ],
  imports: [
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    FlexLayoutModule,
    CommonModule,
    TranslateModule.forChild(),
  ],
})
export class NavigationModule {
  constructor(translate: TranslateService) {
    translate.setTranslation('fr', frNavigationTranslate, true);
    translate.setTranslation('en', enNavigationTranslate, true);
    translate.setTranslation('de', deNavigationTranslate, true);
    translate.setTranslation('es', esNavigationTranslate, true);
  }

}
