import {NgModule} from '@angular/core';
import {MapComponent} from './map.component';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    MapComponent
  ],
  exports: [
    MapComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
  ]
})
export class MapModule {
}
