import {Role} from '../../userRole/_model/role';

export class User {
  id: number;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: Role;
}

export interface LoginResponse {
  success: boolean;
  token: string;
  user: User;
}
